import React from 'react';
import ContactUsSection from '../../Components/ContactUsSection';
import FrequentlyAskQuestion from '../ContactUs/FrequentlyAskQuestion';
import HeroSection from './HeroSection';
import Questions from './Questions';
import ServicesSuport from './ServicesSuport';

function FAQ(props) {
    return (
        <div>
            <HeroSection/>
            <FrequentlyAskQuestion />
            {/* <ServicesSuport/> */}
            {/* <Questions/> */}
            <ContactUsSection/>
        </div>
    );
}

export default FAQ;