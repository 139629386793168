import React from "react";
import styled from "styled-components";
import TraingleImage from "../../../../Images/triangle.png";

const Root = styled.div`
padding: 5rem 0;
width: 1160px;
margin: 0 auto;
@media(max-width: 1200px){
width: 100%;
margin: 0 20ppx;
}
`;
const TextWrapper = styled.div`
display: flex;
flex-direction: column;
`;
const Text = styled.text`
  font-size: 18px;
  line-height: 1.33;
  text-align: center;
  color: #1c1c1c;
  ${(p)=> p.BoldText && `
    font-size: 36px;
  font-weight: 400;
  line-height: 0.86;
  text-align: center;
  color: #1c1c1c;
  padding: 20px 0;
  `}
`;
const ButtonWrapper = styled.div`
display: flex;
justify-content: center;
`;
const Button = styled.button`
background-color: #23744e;
    border: 1px solid #ffb300;
    padding: 18px 42px;
    color: #ffb300;
    position: relative;
    font-weight: 600;
    font-size: 17px;
`;
const Image = styled.img`
position: absolute;
  height: 56.1px;
  top: 0;
  right: 0;
`;
const Span = styled. span`
font-size: 36px;
font-weight: bold;
line-height: 0.86;
text-align: center;
color: #175d3c;
`;
function ContactUs(props) {
  return (
    <Root>
      <TextWrapper>
        <Text>Did’t find and answer?</Text>

        <Text BoldText>
          Call us at:<Span>+971 56 607 4725</Span>
        </Text>
        <ButtonWrapper>
          <Button>Contact Us <Image src={TraingleImage} /></Button>
        </ButtonWrapper>
      </TextWrapper>
    </Root>
  );
}

export default ContactUs;
