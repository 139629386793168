import React from "react";
import Navigation from "../../Components/Navigation";
import styled from "styled-components";
import BgImage from "../../Images/top-banner.jpg";
import BrandImage from '../../Images/top-banner-hands.png';
import LogoImage from '../../Images/expand-icon.png';

const Root = styled.div`
  background-image: url(${BgImage});
  width: 100%;
  background-position:center;
  background-size:100% cover;
`;
const Container = styled.div`
  width: 1160px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 20px;
  }
`;
const DataWrapper = styled.div`
 display:flex;
 flex-direction:column;
 justify-content: center;
 align-items: center;
 width:100%;
 padding:100px 0 0 0;
`;
const Heading = styled.div`
font-size: 90px;
color:#fff;
font-weight:bold;
@media(max-width: 768px){
font-size: 70px;
}
`;

const ImageSection = styled.div`
    width: 100%;
`;
const Image = styled.img`
   ${(p)=> p.Brandimage && `
   width: 100%;
   margin-bottom: -4px;
   `}
`;
function HeroSection(props) {
  return (
    <Root>
      <Container>
        <Navigation />
        <DataWrapper>
          <Heading>exp
            <Image src={LogoImage}/>
         and Business  </Heading>
          <Heading>in <span style={{color:"#0bd07b"}}>DUBAI</span></Heading>
          <ImageSection>
             <Image Brandimage src={BrandImage}/>
          </ImageSection>
        </DataWrapper>
      </Container>
    </Root>
  );
}

export default HeroSection;
