import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
padding: 8rem 0;
@media(max-width:767px){
  padding: 5rem 0; 
}
`;
const TextWrapper = styled.div` 
width: 1160px;
margin: 0 auto;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
@media(max-width:1200px){
width: 100%;
padding: 0 20px;
}
`;
const Text = styled.text`
 font-size: 22px;
  font-weight: 500;
  text-align: center;
  color: #06613a;

  ${(p)=> p.BoldText && `
    font-family: Roboto;
  font-size: 42px;
  font-weight: bold;
  line-height: 2.12;
  text-align: center;
  color: #0b0e13;
  @media(max-width:767px){
    font-size:30px;
    margin:20px 0px;
    line-height:1;
  }
  `}
  ${(p)=> p.Paragraph && `
   font-family: Roboto;
  font-size: 20px;
  line-height: 1.7;
  text-align: center;
  color: #0b0e13;
  @media(max-width:767px){
    font-size: 16px;
    line-height: 1.5;
  }
  `}
`;

function Liabilities(props) {
    return (
        <Root>
         <TextWrapper>
         <Text>Complete Guidance for E-services in Dubai</Text>
         <Text BoldText> What is the Process for Getting an Instant License?</Text>
         <Text Paragraph> The procedure for obtaining a business license in Dubai differs depending on the several processes involved, such as paperwork, business requirements, and corporate activities. Because an instant license is exempt from the requirement of a 12-month lease arrangement, it does not require tenancy agreements like a conventional business license. </Text>
         </TextWrapper>
        </Root>
    );
}

export default Liabilities;