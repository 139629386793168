import React, { useState } from "react";
import { 
    DesktopView, 
    Heading, 
    HeadingWrapper, 
    Icon, 
    Link, 
    LinkWrapper, 
    MobileView, 
    Root } from "./StyledComponents/FooterStyledComponents";
    import { useNavigate } from "react-router-dom";

const FooterPageLink = () => {
    const [showFloxyPayLink, setShowFloxyPlayLink] = useState(false);
    const [showdiscoverLink, setShowdiscoverLink] = useState(false);
    const [showCommunityLink, setCommunityLink] = useState(false);
    const [showResourcesLink, setShowResourcesLink] = useState(false);
    const [showLegalLisk, setShowLegalLink] = useState(false);
    const navigate = useNavigate();
    return (
        <Root>
            <LinkWrapper>
                <HeadingWrapper onClick={() => { setShowFloxyPlayLink(!showFloxyPayLink) }}><Heading>Dubai Startup</Heading>
                    {showFloxyPayLink ? <Icon className="fa-solid fa-minus" /> : <Icon className="fa-solid fa-plus" />}
                </HeadingWrapper>
                <DesktopView>
                    {/* <Link href="/">Center Features</Link> */}
                    {/* <Link href="/">Gallery</Link> */}
                    <Link onClick={()=>navigate("/")}>Home</Link>
                    <Link onClick={()=>navigate("/services")}>Services</Link>
                    <Link onClick={()=>navigate("/FAQ")} >FAQ</Link>
                    <Link onClick={()=>navigate("/ContactUs")}>Contact Us</Link>
                    {/* <Link href="/">Sitemap</Link> */}
                </DesktopView>
                {showFloxyPayLink && <MobileView>
                    {/* <Link href="/">Center Features</Link> */}
                    {/* <Link href="/">Gallery</Link> */}
                    <Link onClick={()=>navigate("/")}>Home</Link>
                    <Link onClick={()=>navigate("/services")}>Services</Link> 
                    <Link onClick={()=>navigate("/FAQ")} >FAQ</Link>
                    <Link onClick={()=>navigate("/ContactUs")}>Contact Us</Link>
                    {/* <Link href="/">Sitemap</Link> */}
                </MobileView>}
            </LinkWrapper>
            <LinkWrapper>
                <HeadingWrapper onClick={() => {
                    setShowdiscoverLink(!showdiscoverLink)
                }}><Heading>Our Services</Heading>
                    {showdiscoverLink ? <Icon className="fa-solid fa-minus" /> : <Icon className="fa-solid fa-plus" />}</HeadingWrapper>
                <DesktopView>
                    <Link onClick={()=>navigate("/CompanyRegistration")}> Company Registration</Link>
                    <Link onClick={()=>navigate("/LLCCompanyFormation")}>LLC Company Formation in Dubai</Link>
                    <Link onClick={()=>navigate("/CommercialLicense")} >Commercial License</Link>
                    <Link onClick={()=>navigate("/PROVisa")} >PRO Visa Services</Link>
                    <Link onClick={()=>navigate("/EjariRegistration")}>Ejari Registration</Link>
                    <Link onClick={()=>navigate("/Eservices")} >E-services</Link>
                    <Link onClick={()=>navigate("/VATRegistration")}>VAT Registration and VAT Consultancy</Link>
                    <Link onClick={()=>navigate("/EtraderLicense")}>E-trader License</Link>
                </DesktopView>
                {showdiscoverLink && <MobileView>
                    <Link onClick={()=>navigate("/CompanyRegistration")}>Company Registration</Link>
                    <Link onClick={()=>navigate("/LLCCompanyFormation")} >LLC Company Formation in Dubai</Link>
                    <Link onClick={()=>navigate("/CommercialLicense")} >Commercial License</Link>
                    <Link onClick={()=>navigate("/PROVisa")}>PRO Visa Services</Link>
                    <Link onClick={()=>navigate("/EjariRegistration")} >Ejari Registration</Link>
                    <Link onClick={()=>navigate("/Eservices")} >E-services</Link>
                    <Link onClick={()=>navigate("/VATRegistration")}>VAT Registration and VAT Consultancy</Link>
                    <Link onClick={()=>navigate("/EtraderLicense")} >E-trader License</Link>
                </MobileView>}
            </LinkWrapper>
            <LinkWrapper>
                <HeadingWrapper onClick={() => { setCommunityLink(!showCommunityLink) }}>
                    <Heading>Community</Heading>
                    {showCommunityLink ? <Icon className="fa-solid fa-minus" /> : <Icon className="fa-solid fa-plus" />}
                </HeadingWrapper>
                <DesktopView>
                    <Link href="https://www.facebook.com/profile.php?id=100087252024835" target="_blank">Facebook</Link>
                    <Link href="https://www.instagram.com/dubai.startup" target="_blank">Instagram</Link>
                    <Link href="https://www.linkedin.com/company/dubai-startup/" target="_blank">Linked-In</Link>
                    <Link href="https://twitter.com/dubai_startup" target="_blank">Twitter</Link>
                    <Link href="https://in.pinterest.com/dubai_startup/" target="_blank">Pinterest</Link>
                </DesktopView>
                {showCommunityLink && <MobileView>
                    <Link href="https://www.facebook.com/profile.php?id=100087252024835" target="_blank">Facebook</Link>
                    <Link href="https://www.instagram.com/dubai.startup" target="_blank">Instagram</Link>
                    <Link href="https://www.linkedin.com/company/dubai-startup/" target="_blank">Linked-In</Link>
                    <Link href="https://twitter.com/dubai_startup" target="_blank">Twitter</Link>
                    <Link href="https://in.pinterest.com/dubai_startup/" target="_blank">Pinterest</Link>
                </MobileView>}
                {/* <HeadingWrapper onClick={() => { setShowResourcesLink(!showResourcesLink) }}>
                    <Heading>Resources</Heading>
                    {showResourcesLink ? <Icon className="fa-solid fa-minus" /> : <Icon className="fa-solid fa-plus" />}
                </HeadingWrapper> */}
                {/* <DesktopView>
                    <Link onClick={()=>navigate("/")}>API Documentation</Link>
                    <Link onClick={()=>navigate("/")}>Help Center</Link>
                </DesktopView> */}
                {/* {showResourcesLink && <MobileView>
                    <Link onClick={()=>navigate("/")}>API Documentation</Link>
                    <Link onClick={()=>navigate("/")}>Help Center</Link>
                </MobileView>} */}
            </LinkWrapper>
            <LinkWrapper>
                <HeadingWrapper onClick={() => { setShowLegalLink(!showLegalLisk) }}>
                    <Heading>Legal</Heading>
                    {showLegalLisk ? <Icon className="fa-solid fa-minus" /> : <Icon className="fa-solid fa-plus" />}
                </HeadingWrapper>
                <DesktopView>
                    <Link onClick={()=>navigate("/Termsofuse")}>Terms of Use</Link>
                    <Link onClick={()=>navigate("/")}>Security</Link>
                    <Link onClick={()=>navigate("/")}>User Agreement</Link>
                    <Link onClick={()=>navigate("/")}>Refund Policy</Link>
                </DesktopView>
                {showLegalLisk && <MobileView>
                    <Link onClick={()=>navigate("/")}>Privacy Policy</Link>
                    <Link onClick={()=>navigate("/")}>Security</Link>
                    <Link onClick={()=>navigate("/")}>User Agreement</Link>
                    <Link onClick={()=>navigate("/")}>Refund Policy</Link>
                </MobileView>}
            </LinkWrapper>
        </Root>
    )
};

export default FooterPageLink;