import React from "react";
import styled from "styled-components";
import Lock from "../../../../Images/Lock.png";

const Root = styled.div`
  padding: 5rem 0;
  background: linear-gradient(180deg, rgb(1 58 32) 20%, rgb(0, 0, 0) 100%);
`;
const Wrapper = styled.div`
display: flex;
justify-content: space-evenly;
align-items: center;
text-align: center;
  display: flex;
  width: 1160px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 20px;
  }
  @media(max-width:767px){
    flex-direction: column;
  }
`;
const Text = styled.div`
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
  text-align: left;
  color: #fff;
  ${(p) =>
    p.BoldText &&
    `
font-size: 42px;
  font-weight: bold;
  line-height: 2.12;
  text-align: left;
  color: #fff;
  @media(max-width:767px){
    font-size:30px;
  }
`}
  ${(p) =>
    p.Paragraph &&
    `
 font-size: 20px;
  line-height: 1.7;
  text-align: left;
  color: #fff;
  width: 75%;
  @media(max-width:767px){
    width:100%;
  }
`}
`;
const LeftSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media(max-width:1200px){
    width:40%;
  }
  @media(max-width:767px){
    display: none;
  }
`;
const Image = styled.img`
 width:fit-content;
`;
const RightsideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  text-align: start;
  @media(max-width:1200px){
    width:60%;
  }
  @media(max-width:767px){
    width:100%;
  }
`;
function Security(props) {
  return (
    <Root>
      <Wrapper>
        <LeftSideWrapper>
          <Image src={Lock} />
        </LeftSideWrapper>
        <RightsideWrapper>
          {/* <Text>With us, you are safe!</Text> */}
          <Text BoldText>With us, you are safe!</Text>
          <Text Paragraph>
          The easiest and most practical approach to registering a company is through the right business consultants. The main advantage of this is the reduction in taxes and legal costs. Consult today! 
          </Text>
        </RightsideWrapper>
      </Wrapper>
    </Root>
  );
}

export default Security;
