import React from "react";
import styled from "styled-components";
import PhoneIcon from "../../Images/phone.png";
import MailIcon from "../../Images/mail.png";
import LocationIcon from "../../Images/location.png";
import FaqIcon from "../../Images/faq.png";
import { useNavigate } from "react-router-dom";
const Root = styled.div`

  padding: 150px 0;
  background-color: #eeeeee;
  background-image: linear-gradient(#fff, #eeeeee);
  @media (max-width: 921px) {
    padding-top: 75px;
  }
  @media (max-width: 768px) {
    padding: 150px 0 30px;
  }
`;
const Container = styled.div`
  width: 1160px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 20px;
  }
  @media (max-width: 921px) {
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 75px;
  }
  @media (max-width: 700px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;
const Card = styled.div`
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 7px 24px 0 rgba(0, 0, 0, 0.14);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
`;
const IconWrapper = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: solid 1px #c3c9d9;
  margin-top: -50px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 700px) {
    width: 60px;
    height: 60px;
  }
`;
const Info = styled.div`
  color: #565656;
  font-size: 14px;
  text-align: center;
  font-weight: 300;
  margin-top: 20px;
  padding: 0 0 20px 0;
  margin-bottom: 20px;
${(p)=> p.bastionex && `

  font-size: 24px;
  line-height: 1;
  font-weight: normal;
  text-align: center;
  color: #565656;
`}
${(p)=> p.Campbell && `
  font-size: 16px;
  line-height: 1.5;
  font-weight: normal;
  text-align: center;
  color: #565656;
`}
  
`;
const Text = styled.div`
  margin-top: 20px;
  font-size: 26px;
  padding: 15px 0;
  font-weight: bold;
  text-align: center;
  color: #010908;
  
`;
const Image = styled.img`
  width: 40px;
  @media (max-width: 700px) {
    width: 28px;
  }
`;
const LinkText = styled.div`
  font-size: 14px;
  margin-bottom: 30px;
  cursor: pointer;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #1c9f28;
`;
const LargeText = styled.div`
  font-size: 29px;
  font-weight: bold;
  line-height: 0.8;
  text-align: center;
  color: #565656;
`;
export default function ContactInfoSection() {
  const navigate = useNavigate();
  return (
    <Root>
      <Container>
        <Card>
          <IconWrapper>
            <Image src={PhoneIcon} />
          </IconWrapper>
          <Text>Call Us</Text>
          <Info>
            <LargeText>+971 56 607 4725</LargeText>
          </Info>
          <LinkText>Call Now</LinkText>
        </Card>
        <Card>
          <IconWrapper>
            <Image src={MailIcon} />
          </IconWrapper>
          <Text>Mail US</Text>
          <Info bastionex>info@dubaistartup.org</Info>
          <LinkText>Drop Us a Mail</LinkText>
        </Card>
        <Card>
          <IconWrapper>
            <Image src={LocationIcon} />
          </IconWrapper>
          <Text>Meet Us</Text>
          <Info Campbell>Aspect Tower 
Building number 125 , Zone A - 20th Floor - Al Mustaqbal St - Business Bay - Dubai</Info>
          <LinkText>Find Us On Map</LinkText>
        </Card>
        <Card>
          <IconWrapper>
            <Image src={FaqIcon} />
          </IconWrapper>
          <Text>FAQ’s</Text>
          <Info Campbell>How can we help you. Search in FAQ’s</Info>
          <LinkText onClick={()=> navigate("/FAQ")}>Know More</LinkText>
        </Card>
      </Container>
    </Root>
  );
}
