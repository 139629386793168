import React from "react";
import styled from "styled-components";
import Banner from "../../../../Images/traders3rdsection.png";
import RightIcon from "../../../../Images/right.png";

const Root = styled.div`
  padding: 5rem 0 5rem 0;
  width: 1160px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 4rem 20px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  @media(max-width:767px){
    flex-direction: column;
    align-items: center;
  }
`;
const LeftsideWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content:center;
  align-items: center;
  @media(max-width:767px){
   display: none;
  }
`;
const Image = styled.img`
width:100%;
`;
const RightsideWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  @media(max-width:767px){
   width:100%;
  }
`;
const Text = styled.text`
  font-size: 42px;
  font-weight: bold;
  text-align: left;
  color: #0b0e13;
  ${(p) => p.BoldText && `
    @media(max-width:767px){
      font-size: 30px;
    }
  `}
  ${(p) =>
    p.Paragraph &&
    `
    font-weight: lighter;
    font-family: Roboto;
  font-size: 18px;
  line-height: 1.33;
  text-align: left;
  color: #0b0e13;
  margin: 1rem 0;
  text-align: left;
  color: #0b0e13;
  @media(max-width:767px){
      font-size: 16px;
      margin:0;
    }
  `}
  ${(p) =>
    p.PointsText &&
    `
    font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
  text-align: left;
  color: #292e3c;
  padding-left: 1rem;
  width:100%;
  `}
`;
const PointsWrapper = styled.div`
  display: flex;
  padding: 15px 0;
`;
const IconWrapper = styled.div`
  width: 25px;
  height: 25px;
  background-color: #055d2d;
  border-radius: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Icon = styled.img``;

function RequiredDocumentation(props) {
  return (
    <Root>
      <Wrapper>
        <LeftsideWrapper>
          <Image src={Banner} />
        </LeftsideWrapper>
        <RightsideWrapper>
          <Text BoldText>Requirements for the e-trader license in Dubai </Text>
    
          <Text Paragraph>
          Before issuing the e-trader license in Dubai, the DED will review the business strategy, the provided documentation, and the applicant's eligibility. If all the information and documentation are accurate, you will receive the e-trader license within three to five business days.
          </Text>
          <Text Paragraph>        
            <Text Paragraph>
            The applicant must fulfill the following conditions for the intended business activity:
          </Text>
          </Text>
          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>The applicant must be of 21 years of age or above.</Text>
          </PointsWrapper>
          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            The candidate must reside in the UAE, be a UAE native, or be a citizen of one of the GCC nations.
            </Text>
          </PointsWrapper>
          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            	There are two kinds of e-trader licenses: Professional and commercial.
            </Text>
          </PointsWrapper>

          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            Both forms of e-trader license applications can be made in Dubai by Emiratis and residents of the GCC. However, only professional e-trader licenses are available to ex-pats.
            </Text>
          </PointsWrapper>

        </RightsideWrapper>
      </Wrapper>
    </Root>
  );
}

export default RequiredDocumentation;
