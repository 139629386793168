import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
padding: 8rem 0;
@media(max-width:767px){
  padding: 5rem 0; 
}
`;
const TextWrapper = styled.div` 
width: 1160px;
margin: 0 auto;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
@media(max-width:1200px){
width: 100%;
padding: 0 20px;
}
`;
const Text = styled.text`
 font-size: 22px;
  font-weight: 500;
  text-align: center;
  color: #06613a;

  ${(p)=> p.BoldText && `
    font-family: Roboto;
  font-size: 42px;
  font-weight: bold;
  line-height: 2.12;
  text-align: center;
  color: #0b0e13;
  @media(max-width:767px){
    font-size:30px;
    margin:20px 0px;
    line-height:1;
  }
  `}
  ${(p)=> p.Paragraph && `
   font-family: Roboto;
  font-size: 20px;
  line-height: 1.7;
  text-align: center;
  color: #0b0e13;
  @media(max-width:767px){
    font-size: 16px;
    line-height: 1.5;
  }
  `}
`;

function Liabilities(props) {
    return (
        <Root>
         <TextWrapper>
         <Text>Applications for visas and trade licenses made easy</Text>
         <Text BoldText> What are PRO visa services?</Text>
         <Text Paragraph> The processing of official paperwork and documents, such as visa applications and corporate trade license documentation and approvals, is what is referred to as PRO services in Dubai. The document-clearing services provided by PRO are of the highest caliber. There are various legal processes for founding and registering corporations in Dubai. However, you may make the application process far less complicated and time-consuming with Pro Visa Services. </Text>
         </TextWrapper>
        </Root>
    );
}

export default Liabilities;