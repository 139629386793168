import React from "react";
import styled from "styled-components";
import BgImageOne from "../../Images/Serviceinfo.jpg";
import BgImageTwo from "../../Images/servicesInfo2.jpg";
import BgImageThree from "../../Images/commercial-license.jpg";
import BgImageFour from "../../Images/Pro-Visa-services.jpg";
import BgImageFive from "../../Images/Ejari.jpg";
import BgImageSix from "../../Images/E-services.jpg";
import BgImageSeven from "../../Images/VAT-registration.jpg";
import BgImageEight from "../../Images/E-trader.jpg";
import { useNavigate } from "react-router-dom";

const Root = styled.div`
  padding: 70px 0;
`;
const Container = styled.div`
  width: 1160px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 20px;
  }
`;
const Heading = styled.div`
  color: #282828;
  font-size: 46px;
  @media(max-width: 768px){
font-size: 36px;
  }
`;
const GridItem = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;
  margin-top: 30px;  
  @media(max-width: 768px){
    grid-template-columns: 1fr 1fr;
  } 
  @media(max-width: 400px){
    grid-template-columns: 1fr;
  } 
`;
const CardWrapper = styled.div`
  box-shadow: 0px 7px 35px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px rgba(11, 158, 95, 0.28);
  position: relative;
  width:100%;
  padding-top:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-position:100% 100%;
  background-size: cover;
  cursor: pointer;
  :hover{
    background-image:url(${BgImageOne});
  }
`;

const CardWrapperTwo = styled(CardWrapper)`
  :hover{
    background-image:url(${BgImageTwo});
  }
`;
const CardWrapperThree = styled(CardWrapper)`
  :hover{
    background-image:url(${BgImageThree});
  }
`;
const CardWrapperFour = styled(CardWrapper)`
  :hover{
    background-image:url(${BgImageFour});
  }
`;
const CardWrapperFive = styled(CardWrapper)`
  :hover{
    background-image:url(${BgImageFive});
  }
`;
const CardWrapperSix = styled(CardWrapper)`
  :hover{
    background-image:url(${BgImageSix});
  }
`;
const CardWrapperSeven = styled(CardWrapper)`
  :hover{
    background-image:url(${BgImageSeven});
  }
`;
const CardWrapperEight = styled(CardWrapper)`
  :hover{
    background-image:url(${BgImageEight});
  }
`;

// const ImageWrapper = styled.img`
//   opacity: 0.6;
//   width: 100%;
//   object-fit: cover;
//   height: 168px;
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: 10;
//   ${CardWrapper}:hover & {
//     opacity: 1;
//     visibility: visible;
//   }
// `;
const Data = styled.div`
  position: absolute;
  font-size: 26px;
  display: flex;
  top:40%;
  justify-content: center;
  align-items: center;
  text-align: left;
  ${CardWrapper}:hover & {
    color: #fff;
  }
`;
export default function BussinessModel() {
  const navigate = useNavigate();
  return (
    <Root>
      <Container>
        <Heading>
          What’s best
          <br /> for my business model?
        </Heading>
        <GridItem>
          <CardWrapper onClick={()=> navigate("/CompanyRegistration")}>
            <Data>
            Company  <br />
            Registration
            </Data>
          </CardWrapper>

           <CardWrapperTwo onClick={()=> navigate("/LLCCompanyFormation")}>
            <Data>
            LLC Company 
              <br /> Formation
            </Data>
          </CardWrapperTwo>

          <CardWrapperThree onClick={()=> navigate("/CommercialLicense")}>
            <Data>
            Commercial  <br />
            License
            </Data>
          </CardWrapperThree>

          <CardWrapperFour  onClick={()=> navigate("/PROVisa")}>
            <Data>
            PRO Visa
              <br /> 
            </Data>
          </CardWrapperFour>

          <CardWrapperFive  onClick={()=> navigate("/EjariRegistration")}>
            <Data>
            Ejari 
              <br />
              Registration
            </Data>
          </CardWrapperFive>

          <CardWrapperSix  onClick={()=> navigate("/Eservices")}>
            <Data>
            E-services
              <br />
            
            </Data>
          </CardWrapperSix>

          <CardWrapperSeven  onClick={()=> navigate("/VATRegistration")}>
            <Data>
            VAT Registration
              <br />
              & Consultancy
            </Data>
          </CardWrapperSeven>
          
          <CardWrapperEight  onClick={()=> navigate("/EtraderLicense")}>
            <Data>
            E-trader 
              <br />
              License
            </Data>
          </CardWrapperEight> 


        </GridItem>
      </Container>
    </Root>
  );
}
