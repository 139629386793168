import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { StateManagementContext } from "../ContextApi/ContextApi";
import LogoImage from "../Images/logo.png";

const Root = styled.div`
  width: 100%;
`;
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1160px;
  margin: 0 auto;
  padding: 10px 0;
  align-items: center;
  position: relative;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 20px;
  }
`;
const LeftWrapper = styled.div`
  width: 50%;
  cursor: pointer;
  @media (max-width: 1160px) {
    width: 40%;
  }
  @media (max-width: 974px) {
    width: 25%;
  }
`;
const RightWrapper = styled.div`
  display: flex;
  width: 35%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1160px) {
    width: 45%;
  }
  @media (max-width: 974px) {
    width: 55%;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;
const Link = styled.div`
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  position: relative;
  font-weight: 300;
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

const Button = styled.button`
  border: 1px solid #ffb300;
  color: #ffb300;
  background: none;
  padding: 10px 20px;
  cursor: pointer;
`;
const NavLink = styled.div`
  color: rgba(0, 0, 0, 0.6);
  position: relative;
  display: flex;
  cursor: pointer;
`;
const DropDown = styled.div`
  color: white;
  cursor: pointer;
  padding-left: 5px;
  @media (max-width: 767px) {
    display: none;
  }
`;
const ListWrapper = styled.div`
  width: 250px;
  /* padding: 10px 0; */
  background-color: #fff;
  box-shadow: 0 0 49px 0 rgba(0, 0, 0, 0.11);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 30px;
  right: -45px;
  border-radius: 7px;
  z-index: 10;
  @media (max-width: 767px) {
    width: 100%;
    position: relative;
    box-shadow: none;
    background: none;
    left: 0;
    top: 0;
  }
`;
const Option = styled.div`
  font-size: 14px;
  padding: 12px 25px;
  cursor: pointer;
  :hover {
    background-color: #23744e;
    color: #fff;
    @media (max-width: 767px) {
      background: none;
      color: #fff;
    }
  }
  @media (max-width: 767px) {
    background: none;
    color: #fff;
    font-size: 14px;
    font-weight: 300;
  }
`;
const ServicesWrapper = styled.div`
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
const MobileViewLink = styled.div`
  background-color: #002a12;
  position: fixed;
  top: 0px;
  right: 0px;
  height: 100vh;
  width: 300px;
  z-index: 20;
  max-height: 100vh;
  overflow-y: scroll;
  padding: 20px 0px 0px 20px;
  @media (min-width: 767px) {
    display: none;
  }
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 35%;
  display: none;
  @media (max-width: 767px) {
    display: flex;
    color: #fff;
  }
`;
const CrossIcon = styled.div`
  color: #fff;
  display: flex;
  justify-content: right;
  align-items: right;

  padding-right: 20px;
`;
const Img = styled.img`
  width: 180px;
  @media (max-width: 768px) {
    width: 120px;
  }
`;
export default function Navigation() {
  const navigate = useNavigate();
  const { showDropDown, setShowDropDown, showNvigation, setShowNavigation } =
    useContext(StateManagementContext);
  return (
    <Root>
      <Container>
        <LeftWrapper>
          <Img
            src={LogoImage}
            onClick={() => {
              navigate("/");
            }}
          />
        </LeftWrapper>
        <RightWrapper>
          <Link
            onClick={() => {
              navigate("/");
            }}
          >
            Home
          </Link>
          {/* <Link
            onClick={() => {
              navigate("/");
            }}
          >
            {" "}
            Our Concept
          </Link> */}

          <NavLink>
            <ServicesWrapper
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Link onClick={() => setShowDropDown(!showDropDown)}>
                Our Services
                <DropDown>
                  <i class="fa-solid fa-caret-down"></i>
                </DropDown>
              </Link>
              {showDropDown && (
                <ListWrapper>
                  <Option
                    onClick={() => {
                      navigate("/CompanyRegistration");
                      setShowDropDown(false);
                    }}
                  >
                    Company Registration
                  </Option>
                  <Option
                    onClick={() => {
                      navigate("/LLCCompanyFormation");
                      setShowDropDown(false);
                    }}
                  >
                    LLC Company Formation
                  </Option>
                  <Option
                    onClick={() => {
                      navigate("/CommercialLicense");
                      setShowDropDown(false);
                    }}
                  >
                    Commercial License
                  </Option>
                  <Option
                    onClick={() => {
                      navigate("/PROVisa");
                      setShowDropDown(false);
                    }}
                  >
                    PRO Visa
                  </Option>
                  <Option
                    onClick={() => {
                      navigate("/EjariRegistration");
                      setShowDropDown(false);
                    }}
                  >
                    Ejari Registration
                  </Option>
                  <Option
                    onClick={() => {
                      navigate("/Eservices");
                      setShowDropDown(false);
                    }}
                  >
                    E-services
                  </Option>
                  <Option
                    onClick={() => {
                      navigate("/VATRegistration");
                      setShowDropDown(false);
                    }}
                  >
                    VAT Registration & Consultancy
                  </Option>
                  <Option
                    onClick={() => {
                      navigate("/EtraderLicense");
                      setShowDropDown(false);
                    }}
                  >
                    E-trader License
                  </Option>
                  <Option
                    onClick={() => {
                      navigate("/services");
                      setShowDropDown(false);
                    }}
                  >
                    View All Services
                  </Option>
                </ListWrapper>
              )}
            </ServicesWrapper>
          </NavLink>
          {/* <Link
          
            onClick={() => {
              navigate("/");
            }}
          >
            Center Features
          </Link> */}
          {/* <Link
            onClick={() => {
              navigate("/");
            }}
          >
            Blog
          </Link> */}
          <Link
            onClick={() => {
              navigate("/FAQ");
            }}
          >
            {" "}
            FAQ
          </Link>
          <Link
            onClick={() => {
              navigate("/ContactUs");
            }}
          >
            <Button>Contact Us</Button>
          </Link>
        </RightWrapper>
        {showNvigation && (
          <MobileViewLink onClick={(e) =>e.stopPropagation()}>
            <CrossIcon
              onClick={() => {
                setShowNavigation(false);
              }}
            >
              <i class="fa-solid fa-xmark"></i>
            </CrossIcon>
            <Link
              onClick={() => {
                navigate("/");
                setShowNavigation(false);
              }}
            >
              Home
            </Link>
            <ServicesWrapper onClick={() => setShowDropDown(!showDropDown)}>
              <Link
              >
                Our Services
              </Link>
              <DropDown>
                <i class="fa-solid fa-caret-down"></i>
              </DropDown>

              <ListWrapper>
                <Option
                  onClick={() => {
                    navigate("/CompanyRegistration");
                    setShowNavigation(false);
                  }}
                >
                  Company Registration
                </Option>
                <Option
                  onClick={() => {
                    navigate("/LLCCompanyFormation");
                    setShowNavigation(false);
                  }}
                >
                  LLC Company Formation
                </Option>
                <Option
                  onClick={() => {
                    navigate("/CommercialLicense");
                    setShowNavigation(false);
                  }}
                >
                  Commercial License
                </Option>
                <Option
                  onClick={() => {
                    navigate("/PROVisa");
                    setShowNavigation(false);
                  }}
                >
                  PRO Visa
                </Option>
                <Option
                  onClick={() => {
                    navigate("/EjariRegistration");
                    setShowNavigation(false);
                  }}
                >
                  Ejari Registration
                </Option>
                <Option
                  onClick={() => {
                    navigate("/Eservices");
                    setShowNavigation(false);
                  }}
                >
                  E-services
                </Option>
                <Option
                  onClick={() => {
                    navigate("/VATRegistration");
                    setShowNavigation(false);
                  }}
                >
                  VAT Registration & Consultancy
                </Option>
                <Option
                  onClick={() => {
                    navigate("/EtraderLicense");
                    setShowNavigation(false);
                  }}
                >
                  E-trader License
                </Option>
                <Option
                  onClick={() => {
                    navigate("/Services");
                    setShowNavigation(false);
                  }}
                >
                  View All Services
                </Option>
              </ListWrapper>
            </ServicesWrapper>
            {/* <Link
              onClick={() => {
                navigate("/Services");
                setShowNavigation(false);
              }}
            >
              Company Registration
            </Link> */}
            <Link
              onClick={() => {
                navigate("/FAQ");
                setShowNavigation(false);
              }}
            >
              FAQ
            </Link>
            <Link
              onClick={() => {
                navigate("/ContactUs");
                setShowNavigation(false);
              }}
            >
              <Button>Contact Us</Button>
            </Link>
          </MobileViewLink>
        )}
        {!showNvigation && (
          <IconWrapper
            onClick={(e) => {
              e.stopPropagation();
              setShowNavigation(true);
            }}
          >
            <i class="fa-solid fa-bars"></i>
          </IconWrapper>
        )}
      </Container>
    </Root>
  );
}
