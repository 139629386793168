import React from "react";
import styled from "styled-components";
import Icon from "../../Images/faq-icon.png";

const Root = styled.div`
  padding: 60px 0;
`;
const Container = styled.div`
  width: 1160px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 20px;
  }
  @media(max-width: 560px){
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
/* text-align: center; */
  }
`;
const Card = styled.div`
  width: calc(50% - 10px);
  border-radius: 5px;
  box-shadow: 0px 7px 24px 0 rgba(0, 0, 0, 0.14);
  background-color: #fff;
  padding: 30px;
  display: flex;
  align-items: center;
  @media(max-width: 560px){
width: 100%;
justify-content: center;
display: flex;
align-items: center;
  }
`;
const IconWrapper = styled.div``;
const InfoWrapper = styled.div`
padding-left: 20px;
@media(max-width: 650px){
padding-left: 5px;
}
`;
const Image = styled.img`
  margin-right: 10px;
`;
const TextHead = styled.div`
  font-size: 24px;
  margin-bottom: 10px;
`;
const Text = styled.div`
  font-weight: 300;
  color: #858585;
 
`;
export default function ServicesSuport() {
  return (
    <Root>
      <Container>
        <Card>
          <IconWrapper>
            <Image src={Icon} />
          </IconWrapper>
          <InfoWrapper>
            <TextHead>Company Registration</TextHead>
            <Text>
            Our legal advisors help expedite the entire process of business registration in Dubai.
            </Text>
          </InfoWrapper>
        </Card>
        <Card>
          <IconWrapper>
            <Image src={Icon} />
          </IconWrapper>
          <InfoWrapper>
            <TextHead>Commercial License</TextHead>
            <Text>
            Be it for a free zone or mainland setup; we help you to do commercial business activities.
            </Text>
          </InfoWrapper>
        </Card>
        <Card>
          <IconWrapper>
            <Image src={Icon} />
          </IconWrapper>
          <InfoWrapper>
            <TextHead>PRO Visa Services</TextHead>
            <Text>
            Get comprehensive services from obtaining a trading license to your VISA application.
            </Text>
          </InfoWrapper>
        </Card>
        <Card>
          <IconWrapper>
            <Image src={Icon} />
          </IconWrapper>
          <InfoWrapper>
            <TextHead>Ejari Registration</TextHead>
            <Text>
            Guided Ejari registration, application, documentation and renewal services.
            </Text>
          </InfoWrapper>
        </Card>
        <Card>
          <IconWrapper>
            <Image src={Icon} />
          </IconWrapper>
          <InfoWrapper>
            <TextHead>E-services</TextHead>
            <Text>
            Our robust digital platform assists with an efficient experience for all its stakeholders.
            </Text>
          </InfoWrapper>
        </Card>
        <Card>
          <IconWrapper>
            <Image src={Icon} />
          </IconWrapper>
          <InfoWrapper>
            <TextHead>User Account</TextHead>
            <Text>
            We meet the needs and requirements of individuals and corporations alike.
            </Text>
          </InfoWrapper>
        </Card>
        <Card>
          <IconWrapper>
            <Image src={Icon} />
          </IconWrapper>
          <InfoWrapper>
            <TextHead>E-trader License</TextHead>
            <Text>
            Pay and receive the license electronically with our professional services.
            </Text>
          </InfoWrapper>
        </Card>
        <Card>
          <IconWrapper>
            <Image src={Icon} />
          </IconWrapper>
          <InfoWrapper>
            <TextHead>Virtual Office</TextHead>
            <Text>
            Get dedicated service to set up your business and operate it virtually anywhere.
            </Text>
          </InfoWrapper>
        </Card>
      </Container>
    </Root>
  );
}
