import React from "react";
import styled from "styled-components";
import ContactUsSection from "../../../../Components/ContactUsSection";
import Navigation from "../../../../Components/Navigation";
import ContactUs from "./ContactUs";
import Hero from "./Hero";
import Liabilities from "./Liabilities";
import RequiredDocumentationtion from "./RequiredDocumentation.js";
import Security from "./Security";
import WhyLiabilities from "./WhyLiabilities";

const Root = styled.div``;
const NavogationWrapper = styled.div`
  background-color: #23744e;
`;
function CompanyRegistration(props) {
  return (
    <Root>
      <NavogationWrapper>
        <Navigation />
      </NavogationWrapper>
      <Hero />
     <Liabilities />
       <WhyLiabilities />
       <RequiredDocumentationtion />
      <Security />
      <ContactUsSection/>
      {/* <ContactUs /> */}
    </Root>
  );
}

export default CompanyRegistration;
