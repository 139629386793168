import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import PhoneIcon from "../../Images/phone_light_icon.png";
import PeopleIcon from "../../Images/name.png";
import EmailIcon from "../../Images/email.png";
import LocationIcon from "../../Images/location_light_img.png";
import WriteIcon from "../../Images/message.png";
import Triangle from "../../Images/triangle.png";

const Root = styled.div`
  background-color: #002a12;
  padding: 80px 0;
  margin-top: -50px;
`;
const Container = styled.div`
  width: 1160px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 20px;
  }
`;
const Form = styled.form`
  margin-top: 40px;
  width: 100%;
`;
const Heading = styled.div`
  font-size: 40px;
  color: #fff;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 24px;
    margin-bottom: 20px;
  }
`;
const Text = styled.div`
  color: #ffb300;
  margin-bottom: 10px;
  font-weight: 300;
  text-align: center;
`;
const InputSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  @media (max-width: 767px) {
    flex-direction: column;
    margin-top: 0px;
  }
`;
const Input = styled.input`
  width: 100%;
  padding: 10px;
  background: none;
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(182, 225, 205, 0.153);
  color: #fff;
  padding: 10px 10px 10px 30px;
  position: relative;
`;

const InputWrapper = styled.div`
  width: calc(50% - 20px);
  position: relative;
  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;
const Image = styled.img`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 16px;
`;
const Button = styled.button`
  background-color: #23744e;
  border: 1px solid #ffb300;
  padding: 15px 30px;
  color: #ffb300;
  position: relative;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
`;
const ImageIcon = styled.img`
  position: absolute;
  height: 48.7px;
  top: 0;
  right: 0;
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;
const Icon = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 16px;
  color: #fff;
  opacity: 0.6;
  cursor: pointer;
`;
const DropDown = styled.div`
  position: absolute;
  z-index: 100;
  top: 40px;
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  box-shadow: 1px 1px 10px 1px rgb(0 0 0 / 11%);
`;
const List = styled.div`
  padding: 15px;
  cursor: pointer;
  :hover {
    background: #23744e;
    color: #fff;
  }
`;
export default function ContactUsForm() {
  const url = "https://mail.dubaistartup.org/postuserdata";
  const [data, setData] = useState({
    name: "",
    phonenumber: "",
    emailid: "",
    message: "",
  });
  const [services, setServices] = useState("Company Registration");
  const [shoDropdown, setShowDropdown] = useState(false);
  function handle(e) {
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
    console.log(newdata);
  }

  function handleSubmit(e) {
    e.preventDefault();
    axios
      .post(url, {
        name: data.name,
        number: data.phonenumber,
        email: data.emailid,
        message: data.message,
        // service:services
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });
  }

  return (
    <Root onClick={() =>{setShowDropdown(false)}}>
      <Container>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Text>GET IN TOUCH</Text>
          <Heading>Contact Form</Heading>
          <InputSection>
            <InputWrapper>
              <Input
                placeholder="Enter Your name here"
                type="text"
                onChange={(e) => handle(e)}
                id="name"
                value={data.name}
              />
              <Image src={PhoneIcon} />
            </InputWrapper>
            <InputWrapper>
              <Input
                placeholder="Enter Your number here"
                type="number"
                onChange={(e) => handle(e)}
                id="phonenumber"
                value={data.phonenumber}
              />
              <Image src={PeopleIcon} />
            </InputWrapper>
          </InputSection>
          <InputSection>
            <InputWrapper>
              <Input
                placeholder="Enter Your email here"
                type="email"
                onChange={(e) => handle(e)}
                id="emailid"
                value={data.emailid}
              />
              <Image src={EmailIcon} />
            </InputWrapper>
            {/* <InputWrapper onClick={(e) =>{e.stopPropagation()}}>
              <Input
                placeholder="Choose Your Services"
                onClick={() => setShowDropdown(!shoDropdown)}
                value={services}
              />
              <Icon onClick={() => setShowDropdown(!shoDropdown)}>
                <i class="fa-solid fa-angle-down"></i>
              </Icon>
              {shoDropdown && (
                <DropDown>
                  <List
                    onClick={() => {
                      setServices("Company Registration");
                      setShowDropdown(false);
                    }}
                  >
                    Company Registration
                  </List>
                  <List
                    onClick={() => {
                      setServices("LLC Company Formation");
                      setShowDropdown(false);
                    }}
                  >
                    LLC Company Formation
                  </List>
                  <List
                    onClick={() => {
                      setServices(" Commercial License");
                      setShowDropdown(false);
                    }}
                  >
                    Commercial License
                  </List>
                  <List
                    onClick={() => {
                      setServices(" PRO Visa");
                      setShowDropdown(false);
                    }}
                  >
                    PRO Visa
                  </List>
                  <List
                    onClick={() => {
                      setServices("Ejari Registration");
                      setShowDropdown(false);
                    }}
                  >
                    Ejari Registration
                  </List>
                  
                  <List
                    onClick={() => {
                      setServices("E-services");
                      setShowDropdown(false);
                    }}
                  >
                   E-services
                  </List>
                  <List
                    onClick={() => {
                      setServices("VAT Registration & Consultancy");
                      setShowDropdown(false);
                    }}
                  >
                    VAT Registration & Consultancy
                  </List>
                  <List
                    onClick={() => {
                      setServices("E-trader License");
                      setShowDropdown(false);
                    }}
                  >
                    E-trader License
                  </List>
                </DropDown>
              )}
            </InputWrapper> */}
          </InputSection>
          <InputSection>
            <InputWrapper style={{ width: "100%" }}>
              <Input
                placeholder="Type your message here"
                type="text"
                onChange={(e) => handle(e)}
                id="message"
                value={data.message}
              />
              <Image src={WriteIcon} />
            </InputWrapper>
          </InputSection>

          <ButtonWrapper>
            <Button>
              Get Quote <ImageIcon src={Triangle} />
            </Button>
          </ButtonWrapper>
        </Form>
      </Container>
    </Root>
  );
}
