import axios from "axios";
import React, { useState } from "react";
import styled from "styled-components";
import Swal from "sweetalert2";
import Loader from "../../../Components/Loader";

const Root = styled.div`
  /* width:500px; */
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Heading = styled.div`
  color: #fff;
  font-size: 34px;
  text-align: center;
  font-weight: 300;
  margin: 40px 0;
`;
// const FormWrapper = styled.form`
// background-color:#424347;
// margin-top:20px;
// display: flex;
// flex-direction: column;
// border-radius:5px;
// `;
// const Textarea = styled.textarea`
// background:none;
// outline:none;
// border:none;
// border-bottom:1px solid #4b4c50;
// color:#fff;
// padding:15px;
// `;
const Input = styled.input`
  background: none;
  outline: none;
  border: none;
  border-bottom: 1px solid #4b4c50;
  padding: 15px;
  color: #fff;
`;
const Button = styled.button`
  background-color: #0b9e5f;
  border: none;
  padding: 15px;
  border-radius: 0px 0px 5px 5px;
  color: #fff;
  cursor: pointer;
`;

const FormTextWrapper = styled.div`
  width: 1200px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
`;
const FormWrapper = styled.div`
  width: 55%;
  margin: 0 auto;
  color: white;
  @media (max-width: 1024px) {
    width: 70%;
    flex-direction: column;
  }
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
`;

const FormContainer = styled.div`
  /* width: 50%; */
  background-color: #3a3a3a;
  border-style: none;
  margin: 0 auto;
  padding: 25px;
  border-radius: 10px;
`;
const FormBoldTextWrapper = styled.div`
  margin: 10px 0 20px 0;
`;
const FormText = styled.text`
  font-size: 28px;
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  margin: 15px 0;
`;
const FiledTextWrapper = styled.div`
  margin: 8px 0;
`;
const FieldText = styled.text`
  font-size: 14px;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
`;
const InputWrapper = styled.div``;
const FieldInput = styled.input`
  width: 100%;
  border: none;
  border-radius: 7px;
  padding: 11px 8px;
  outline: none;
  background-color: rgba(229, 229, 229, 1);
`;

const Span = styled.span`
  color: red;
`;

const FieldSelect = styled.select`
  width: 100%;
  border: none;
  border-radius: 7px;
  padding: 11px 8px;
  outline: none;
  background-color: rgba(229, 229, 229, 1);
`;
const Option = styled.option``;
const Textarea = styled.textarea`
  width: 100%;
  border: none;
  border-radius: 7px;
  padding: 11px 8px;
  outline: none;
  background-color: rgba(229, 229, 229, 1);
`;
const PrivacyTextWrapper = styled.div`
  text-align: start;
`;
const PrivacyText = styled.text`
  color: white;
  font-size: 15px;
  font-weight: 300;
`;

const ButtonWrapper = styled.div``;
const BookButton = styled.button`
  background-color: ${(p) => (p.disabled ? "#ff8989" : "#c93627")};
  color: white;
  font-size: 18px;
  width: 100%;
  margin-top: 40px;
  outline: none;
  border: none;
  border-radius: 7px;
  padding: 10px 0;
  cursor: ${(p) => (p.disabled ? "" : "pointer")};
`;

const A = styled.a`
  color: white;
`;
export default function ServicesInquiry() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [select, setSelect] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [selectError, setSelectError] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const HandleButton = () => {
    setLoading(true);
    console.log(name, email, number, message, select);
    let data = JSON.stringify({
      name,
      email,
      number,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.bastionex.net/contactusform",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        Swal.fire({
          icon: "success",
          title: response.data.message,
          timer: "1000",
        });
        setName("");
        setEmail("");
        setNumber("");
        setMessage("");
        setSelect("");
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          timer: "1000",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);
    validateForm();
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    validateForm();
  };

  const handleNumberChange = (e) => {
    const value = e.target.value;
    setNumber(value);
    validateForm();
  };

  const handleSelectChange = (e) => {
    const value = e.target.value;
    setSelect(value);
    validateForm();
  };

  const handleMessageChange = (e) => {
    const value = e.target.value;
    setMessage(value);
    validateForm();
  };

  const validateForm = () => {
    const isNameValid = /^[A-Za-z\s]+$/.test(name) && name.length >= 3 && name.trim() !== "";
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const isNumberValid = /^\d{10}$/.test(number);

    setNameError(isNameValid ? "" : "Name should be Valid");
    setEmailError(isEmailValid ? "" : "Invalid email address.");
    setNumberError(
      isNumberValid ? "" : "Phone number must be exactly 10 digits."
    );
    setSelectError(select !== "" ? "" : "Service Required field is required.");

    const isFormValid =
      isNameValid && isEmailValid && isNumberValid && select !== "";

    setFormValid(isFormValid);
  };

  return (
    <Root>
      <Heading> </Heading>
      <FormWrapper>
        <FormContainer>
          <FormBoldTextWrapper>
            <FormText>Get A Free Consultation!</FormText>
          </FormBoldTextWrapper>
          <FieldContainer>
            <FiledTextWrapper>
              <FieldText>
                Name <Span> *</Span>
              </FieldText>
            </FiledTextWrapper>
            <InputWrapper>
              <FieldInput
                type="text"
                placeholder="Name"
                value={name}
                onChange={handleNameChange}
              />
            </InputWrapper>
            {nameError && <div style={{ color: "red" }}>{nameError}</div>}
          </FieldContainer>

          <FieldContainer>
            <FiledTextWrapper>
              <FieldText>
                Email ID <Span> *</Span>
              </FieldText>
            </FiledTextWrapper>
            <InputWrapper>
              <FieldInput
                type="email"
                placeholder="Email"
                value={email}
                onChange={handleEmailChange}
              />
            </InputWrapper>
            {emailError && <div style={{ color: "red" }}>{emailError}</div>}
          </FieldContainer>

          <FieldContainer>
            <FiledTextWrapper>
              <FieldText>
                {" "}
                Phone Number <Span> *</Span>
              </FieldText>
            </FiledTextWrapper>
            <InputWrapper>
              <FieldInput
                type="number"
                placeholder="Number"
                value={number}
                onChange={(e) => handleNumberChange(e)}
              />
            </InputWrapper>
            {numberError && <div style={{ color: "red" }}>{numberError}</div>}
          </FieldContainer>

          <FieldContainer>
            <FiledTextWrapper>
              <FieldText>
                Service Required <Span> *</Span>
              </FieldText>
            </FiledTextWrapper>
            <InputWrapper>
              <FieldSelect value={select} onChange={handleSelectChange}>
                <Option value=""> -Select-</Option>
                <Option value="mainland"> Mainland</Option>
                <Option value="freezone">Freezone</Option>
                <Option value="jobseeker">Job Seeker</Option>
                <Option value="other">Other</Option>
              </FieldSelect>
            </InputWrapper>
            {selectError && <div style={{ color: "red" }}>{selectError}</div>}
          </FieldContainer>

          <FieldContainer>
            <FiledTextWrapper>
              <FieldText>
                Tell us more about the business you are looking to start{" "}
                <Span> *</Span>
              </FieldText>
            </FiledTextWrapper>
            <InputWrapper>
              <Textarea
                rows="4"
                cols="50"
                placeholder="message"
                value={message}
                onChange={handleMessageChange}
              />
            </InputWrapper>
          </FieldContainer>

          <PrivacyTextWrapper>
            <PrivacyText>
              By clicking the submit button below, I hereby agree to and accept
              the <A href="#">Privacy Policy</A> &{" "}
              <A href="#">Terms & Conditions</A>{" "}
            </PrivacyText>
          </PrivacyTextWrapper>

          <ButtonWrapper>
            <BookButton onClick={HandleButton} disabled={!formValid}>
              {loading ? <Loader /> : "Book Now"}
            </BookButton>
          </ButtonWrapper>
        </FormContainer>
      </FormWrapper>
    </Root>
  );
}
