import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Root = styled.div`
  background-color: #fff;
`;
const Container = styled.div`
  width: 1160px;
  display: flex;
  margin:0 auto;
  justify-content: right;
  @media (max-width: 1200px) {
    padding: 0px 20px;
    width: 100%;
  }
`;

const LinkWrapper = styled.div`
color:#1c1c1c;
padding: 15px;
display: flex;
align-items: center;
${(p)=>p.left && `
cursor: pointer;
`}
@media(max-width:600px){
  font-size: 12px;
}
`;
const Line = styled.div`
width:1px;
background-color:#dbdbdb ;
display: flex;
`;
const Text = styled.div`
margin-left:10px;
`;
function Header() {
  const navigate = useNavigate();
 
  return (
    <Root>
      <Container>
        <LinkWrapper left onClick={()=> navigate("/ContactUs")}>Book an Appoinment</LinkWrapper>
        <Line></Line>

        <LinkWrapper>
          <i class="fa-solid fa-phone"></i>
 
           <Text>+971 56 607 4725</Text>
        </LinkWrapper>
      </Container>
    </Root>
  );
}

export default Header;
