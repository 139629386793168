import React from "react";
import styled from "styled-components";

const Root = styled.div`
  width: 797px;
  margin: 0 auto;
  margin-bottom: 20px;
  @media (max-width: 837px) {
    width: 100%;
    padding: 0 20px;
  }
`;
const AnsWrapper = styled.div`
  border: solid 1px rgba(105, 199, 159, 0.3);
  padding: 20px;
  border-radius: 0px 0px 5px 5px;
  font-weight: 300;
`;
const HeadingData = styled.div`
  border-radius: 5px;
  box-shadow: 0px 7px 24px 0 rgba(0, 0, 0, 0.14);
  border: solid 1px rgba(105, 199, 159, 0.3);
  background-color: #fff;
  padding: 15px;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  & .fa-solid {
    color: #0b9e5f;
  }
`;
const IconWrapper = styled.div`
  width: 30px;
  display: flex;
  justify-content: right;
  align-items: flex-end;
`;

const IconBackground = styled.div`
 width: 25px;
  height: 25px;
  background-color: #effdf7;
  display: flex;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
export default function QuestionCard({ data, setAccordian, accordian }) {
  return (
    <Root>
      <HeadingData>
        <div
          onClick={() => {
            setAccordian(data.id);
          }}
        >
          {data.question}{" "}
        </div>
        {accordian === data.id ? (
          <IconWrapper
            onClick={() => {
              setAccordian("");
            }}
          >
            <IconBackground><i class="fa-solid fa-chevron-down"></i></IconBackground>
          </IconWrapper>
        ) : (
          <IconWrapper
            onClick={() => {
              setAccordian(data.id);
            }}
          >
            <IconBackground><i class="fa-solid fa-angle-right"></i></IconBackground>  
          </IconWrapper>
        )}
      </HeadingData>
      {accordian === data.id && <AnsWrapper>{data.ans}</AnsWrapper>}
    </Root>
  );
}
