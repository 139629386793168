import React, { useState } from "react";
import styled from "styled-components";
import ContactInfo from "./ServicesInquiry/ContactInfo";
import ServicesInquiry from "./ServicesInquiry/ServicesInquiry";
import ViewMap from './ServicesInquiry/ViewMap'

const Root = styled.div`
  background-color: #1c1f22;
  padding: 70px 0;
`;
const Container = styled.div`
  width: 1160px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 20px;
  }
`;
const LinkSection = styled.div`
  display: flex;
`;
const Link = styled.div`
  text-align: center;
  width: 33.33%;
  border-bottom: 1px solid #807d7e;
  color: #fff;
  padding-bottom: 20px;
  cursor: pointer;
  ${(p) => p.selected && `
   border-bottom: 2px solid  #0b9e5f;
  `};
`;
export default function Inquiry() {
  const [state, setState] = useState("ServiceInquiry");
  return (
    <Root>
      <Container>
        <LinkSection>
          <Link
            selected={state === "ContactInfo"}
            onClick={() => {
              setState("ContactInfo");
            }}
          >
            Contact Info
          </Link>
          <Link
            selected={state === "ServiceInquiry"}
            onClick={() => {
              setState("ServiceInquiry");
            }}
          >
            Service Inquiry
          </Link>
          <Link
            selected={state === "ViewonMap"}
            onClick={() => {
              setState("ViewonMap");
            }}
          >
            View on Map
          </Link>
        </LinkSection>
        {state === 'ServiceInquiry' && <ServicesInquiry />}
        {state === 'ContactInfo' && <ContactInfo />}
        {state === 'ViewonMap' && <ViewMap/>}
      </Container>
    </Root>
  );
}
