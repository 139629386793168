import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
padding: 8rem 0;
@media(max-width:767px){
  padding: 5rem 0; 
}
`;
const TextWrapper = styled.div` 
width: 1160px;
margin: 0 auto;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
@media(max-width:1200px){
width: 100%;
padding: 0 20px;
}
`;
const Text = styled.text`
 font-size: 22px;
  font-weight: 500;
  text-align: center;
  color: #06613a;

  ${(p)=> p.BoldText && `
    font-family: Roboto;
  font-size: 42px;
  font-weight: bold;
  line-height: 2.12;
  text-align: center;
  color: #0b0e13;
  @media(max-width:767px){
    font-size:30px;
    margin:20px 0px;
    line-height:1;
  }
  `}
  ${(p)=> p.Paragraph && `
   font-family: Roboto;
  font-size: 20px;
  line-height: 1.7;
  text-align: center;
  color: #0b0e13;
  @media(max-width:767px){
    font-size: 16px;
    line-height: 1.5;
  }
  `}
`;

function Liabilities(props) {
    return (
        <Root>
         <TextWrapper>
         <Text>Become an e-trader in Dubai with our expert consultation</Text>
         <Text BoldText> What documents are required for an E-trader License?</Text>
         <Text Paragraph> The following is the paperwork needed to apply for an e-commerce license in Dubai: Application form, copies of your passport, two photographs the size of a passport, a current Emirates ID, and proof of residency. You'll be able to expand your business ideas online after you get an e-trader license. You'll get the flexibility you've always desired from this while exhibiting your skills. </Text>
         </TextWrapper>
        </Root>
    );
}

export default Liabilities;