import React, { useState } from "react";
import styled from "styled-components";
import { Data } from "./Data";
import QuestionCard from "./QuestionCard";

const Root = styled.div`
`;
const DataWrapper = styled.div`
font-size: 18px;
`;
export default function Questions() {
  const [accordian , setAccordian] = useState();
  return (
    <Root>
      {Data.map((data,index) =>{
        return(
          <DataWrapper key={index}>
            
                <QuestionCard data={data} setAccordian={setAccordian} accordian={accordian}/>
          </DataWrapper>
        )
      })}
    </Root>
  );
}
