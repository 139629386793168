import React from "react";
import styled from "styled-components";
import Banner from "../../../../Images/Ejari3rdsection.jpg";
import RightIcon from "../../../../Images/right.png";

const Root = styled.div`
  padding: 5rem 0 5rem 0;
  width: 1160px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 4rem 20px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  @media(max-width:767px){
    flex-direction: column;
    align-items: center;
  }
`;
const LeftsideWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content:flex-end;
  align-items: flex-end;
  /* text-align: end; */
  @media(max-width:767px){
   display: none;
  }
`;
const Image = styled.img`
width:100%;
`;
const RightsideWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  @media(max-width:767px){
   width:100%;
  }
`;
const Text = styled.text`
  font-size: 42px;
  font-weight: bold;
  text-align: left;
  color: #0b0e13;
  ${(p) => p.BoldText && `
    @media(max-width:767px){
      font-size: 30px;
    }
  `}
  ${(p) =>
    p.Paragraph &&
    `
    font-weight: lighter;
    font-family: Roboto;
  font-size: 18px;
  line-height: 1.33;
  text-align: left;
  color: #0b0e13;
  margin: 1rem 0;
  text-align: left;
  color: #0b0e13;
  @media(max-width:767px){
      font-size: 16px;
      margin:0;
    }
  `}
  ${(p) =>
    p.PointsText &&
    `
    font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
  text-align: left;
  color: #292e3c;
  padding-left: 1rem;
  width:100%;
  `}
`;
const PointsWrapper = styled.div`
  display: flex;
  padding: 15px 0;
`;
const IconWrapper = styled.div`
  width: 25px;
  height: 25px;
  background-color: #055d2d;
  border-radius: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Icon = styled.img``;

function RequiredDocumentation(props) {
  return (
    <Root>
      <Wrapper>
        <LeftsideWrapper>
          <Image src={Banner} />
        </LeftsideWrapper>
        <RightsideWrapper>
          <Text BoldText>Required Documents for Ejari in Dubai </Text>
    
          <Text Paragraph>
          There is a different set of documentation for each firm in Dubai because there are several types of enterprises.
          </Text>
          <Text Paragraph>        
            <Text Paragraph>
            The list of documents that Ejari requires is as follows:
          </Text>
          </Text>
          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>Document of the security deposit made.</Text>
          </PointsWrapper>
          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            Passport of the tenant, original, signed lease (for non-GCC countries nationals).
            </Text>
          </PointsWrapper>
          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            United Arab Emirates Tenant's Visa (for non-GCC countries nationals)
            </Text>
          </PointsWrapper>

          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            Emirates ID of the tenant
            </Text>
          </PointsWrapper>

          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            In the case of an individual, get a copy of the landlord's passport.
            </Text>
          </PointsWrapper>

          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            A duplicate of a valid power of attorney (if a PoA has signed the contract). 
            </Text>
          </PointsWrapper>
          
          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            Documentation granting power of attorney must be renewed annually to remain in effect.
            </Text>
          </PointsWrapper>
          
          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            A duplicate of the title deed of the rental property
            </Text>
          </PointsWrapper>
        </RightsideWrapper>
      </Wrapper>
    </Root>
  );
}

export default RequiredDocumentation;
