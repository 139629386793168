import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
padding: 8rem 0;
@media(max-width:767px){
  padding: 5rem 0; 
}
`;
const TextWrapper = styled.div` 
width: 1160px;
margin: 0 auto;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
@media(max-width:1200px){
width: 100%;
padding: 0 20px;
}
`;
const Text = styled.text`
 font-size: 22px;
  font-weight: 500;
  text-align: center;
  color: #06613a;

  ${(p)=> p.BoldText && `
    font-family: Roboto;
  font-size: 42px;
  font-weight: bold;
  line-height: 2.12;
  text-align: center;
  color: #0b0e13;
  @media(max-width:767px){
    font-size:30px;
    margin:20px 0px;
    line-height:1;
  }
  `}
  ${(p)=> p.Paragraph && `
   font-family: Roboto;
  font-size: 20px;
  line-height: 1.7;
  text-align: center;
  color: #0b0e13;
  @media(max-width:767px){
    font-size: 16px;
    line-height: 1.5;
  }
  `}
`;

function Liabilities(props) {
    return (
        <Root>
         <TextWrapper>
         <Text>Register your Ejari in Dubai </Text>
         <Text BoldText> What is Ejari?</Text>
         <Text Paragraph> Ejari registration is a relatively easy process in Dubai. Even though the landlord is mainly responsible for maintaining the laws of the property you have rented, you must register for Ejari. Typically, the landlord gives the real estate agent this responsibility, and the tenant will see it through and make all required payments. The procedure is quite simple. All you have to do is to follow the Ejari laws. Otherwise, Real Estate Regulatory Agency (Rera) may impose penalties for non-compliance with the Ejari law.  </Text>
         </TextWrapper>
        </Root>
    );
}

export default Liabilities;