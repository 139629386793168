import React from 'react';
import ContactInfoSection from './ContactInfoSection';
import ContactUsForm from '../ContactUs/ContactUsForm';
import FrequentlyAskQuestion from './FrequentlyAskQuestion';
import Herosection from './Herosection';

function ContactUs(props) {
    return (
        <div>
            <Herosection/>
            <ContactInfoSection/>
            {/* <ContactUsForm/> */}
            <FrequentlyAskQuestion/>
        </div>
    );
}

export default ContactUs;