import React from 'react'
import styled from 'styled-components'
import Navigation from '../../Components/Navigation';
import BgImage from '../../Images/Services-banner-bg.jpg';
import { useNavigate } from 'react-router-dom';

const Root = styled.div`
background-image:url(${BgImage});
padding-bottom:60px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`;
const Heading = styled.div`
 font-family: Roboto;
  font-size: 44px;
  font-weight: normal;
  margin: 3rem 0 0 0;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;

@media(max-width:768px){
font-size: 30px;
}
@media(max-width:425px){
font-size: 20px;
font-weight: 400;
}
`;
const Button = styled.button`
color:#ffb300;
border: solid 2px #ffb300;
background:none;
margin-top:20px;
padding:10px 20px;
font-weight: bold;
font-size: 20px;
cursor: pointer;
`;
export default function HeroSection() {
  const navigate = useNavigate();
  return (
    <Root>
        <Navigation/>
        <Heading>Services Offered by Dubai Startup</Heading>
        <Button onClick={()=> navigate("/ContactUs")}>Talk to Expert</Button>
    </Root>
  )
}
