import React from 'react'
import styled from 'styled-components'
import Questions from '../FAQ/Questions';



const Root = styled.div`
padding:50px 0;
`;
const Heading = styled.div`
font-size: 32px;
margin-bottom:30px;
text-align: center;
color: #1c1c1c;
font-weight:400;
`;
export default function FrequentlyAskQuestion() {
  return (
    <Root>
        <Heading>Frequently asked questions</Heading>
        <Questions/>
    </Root>
  )
}
