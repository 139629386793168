import React, { useState } from "react";
import Navigation from "../../Components/Navigation";
import styled from "styled-components";
import BgImage from "../../Images/Services-banner-bg.jpg";
import axios from "axios";
import Swal from "sweetalert2";
import Loader from "../../Components/Loader";

const Root = styled.div`
  background-image: url(${BgImage});
  /* background-color:black; */
  background-repeat: no-repeat;
  /* padding-bottom: 120px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ListContainer = styled.ul`
  list-style-type: none;
  text-align: start;
  padding: 0 18px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  font-size: 1.3125rem;
  color: white;
  list-style-type: disc;
  padding: 5px 0;
`;

const InfoSection = styled.div`
  width: 55%;
  text-align: center;
  /* margin-top:30px; */
  @media (max-width: 768px) {
    width: 100%;
    padding: 30px 0px;
  }
`;
const Text = styled.div`
  color: #fff;
  font-weight: 500;
  margin-bottom: 30px;
  text-align: start;
  font-size: 2.8rem;
`;

const Button = styled.button`
  color: #ffb300;
  border: solid 2px #ffb300;
  background: none;
  margin-top: 20px;
  padding: 10px 20px;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
`;
const FormTextWrapper = styled.div`
  width: 1200px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  padding: 0 20px;
    @media (max-width: 1200px) {
      width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
`;
const FormWrapper = styled.div`
  width: 45%;
  color: white;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
`;

const FormContainer = styled.div`
  /* width: 50%; */
  background-color: #3a3a3a;
  border-style: none;
  margin: 0 auto;
  padding: 25px;
  border-radius: 10px;
`;
const FormBoldTextWrapper = styled.div`
  margin: 10px 0 20px 0;
`;
const FormText = styled.text`
  font-size: 28px;
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  margin: 15px 0;
`;
const FiledTextWrapper = styled.div`
  margin: 8px 0;
`;
const FieldText = styled.text`
  font-size: 14px;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
`;
const InputWrapper = styled.div``;
const FieldInput = styled.input`
  width: 100%;
  border: none;
  border-radius: 7px;
  padding: 11px 8px;
  outline: none;
  background-color: rgba(229, 229, 229, 1);
`;

const Span = styled.span`
  color: red;
`;

const FieldSelect = styled.select`
  width: 100%;
  border: none;
  border-radius: 7px;
  padding: 11px 8px;
  outline: none;
  background-color: rgba(229, 229, 229, 1);
`;
const Option = styled.option``;
const Textarea = styled.textarea`
  width: 100%;
  border: none;
  border-radius: 7px;
  padding: 11px 8px;
  outline: none;
  background-color: rgba(229, 229, 229, 1);
`;
const PrivacyTextWrapper = styled.div`
  text-align: start;
`;
const PrivacyText = styled.text`
  color: white;
  font-size: 15px;
  font-weight: 300;
`;

const ButtonWrapper = styled.div``;
const BookButton = styled.button`
  background-color: ${(p) => (p.disabled ? "#ff8989" : "#c93627")};
  color: white;
  font-size: 18px;
  width: 100%;
  margin-top: 40px;
  outline: none;
  border: none;
  border-radius: 7px;
  padding: 10px 0;
  cursor: ${(p)=>(p.disabled ? "" : "pointer")};
`;

const A = styled.a`
  color: white;
`;

export default function Herosection() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [select, setSelect] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [selectError, setSelectError] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const HandleButton = () => {
    setLoading(true);
    console.log(name, email, number, message, select);
    let data = JSON.stringify({
      name,
      email,
      number,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.bastionex.net/contactusform",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        Swal.fire({
          icon: "success",
          title: response.data.message,
          timer: "1000",
        });
        setName("");
        setEmail("");
        setNumber("");
        setMessage("");
        setSelect("");
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
          timer: "1000",
        });
      })
      .finally(() => {
       
        setLoading(false); 
      });
      
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);
    validateForm();
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    validateForm();
  };

  const handleNumberChange = (e) => {
    const value = e.target.value;
    setNumber(value);
    validateForm();
  };

  const handleSelectChange = (e) => {
    const value = e.target.value;
    setSelect(value);
    validateForm();
  };

  const handleMessageChange = (e) => {
    const value = e.target.value;
    setMessage(value);
    validateForm();
  };

  const validateForm = () => {

    const isNameValid = /^[A-Za-z\s]+$/.test(name) && name.length >= 3 && name.trim() !== "";
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const isNumberValid = /^\d{10}$/.test(number);

    setNameError(isNameValid ? "" : "Name should be Valid");
    setEmailError(isEmailValid ? "" : "Invalid email address.");
    setNumberError(
      isNumberValid ? "" : "Phone number must be exactly 10 digits."
    );
    setSelectError(select !== "" ? "" : "Service Required field is required.");

  
    const isFormValid =
      isNameValid && isEmailValid && isNumberValid && select !== "";

    setFormValid(isFormValid); 
  };

  return (
    <Root>
      <Navigation />
      <FormTextWrapper>
        <InfoSection>
          <Text>Kickstart Your Entrepreneurial Journey in Dubai.</Text>
          <ListContainer>
            <ListItem>Free lifetime Visa</ListItem>
            <ListItem>24/7 Financial Assistance</ListItem>
            <ListItem>Hassle-free Business Setup</ListItem>
          </ListContainer>
        </InfoSection>
        <FormWrapper>
          <FormContainer>
            <FormBoldTextWrapper>
              <FormText>Get A Free Consultation!</FormText>
            </FormBoldTextWrapper>
            <FieldContainer>
              <FiledTextWrapper>
                <FieldText>
                  Name <Span> *</Span>
                </FieldText>
              </FiledTextWrapper>
              <InputWrapper>
                <FieldInput
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={handleNameChange}
                  required
                />
              </InputWrapper>
              {nameError && <div style={{ color: "red" }}>{nameError}</div>}
            </FieldContainer>

            <FieldContainer>
              <FiledTextWrapper>
                <FieldText>
                  Email ID <Span> *</Span>
                </FieldText>
              </FiledTextWrapper>
              <InputWrapper>
                <FieldInput
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={handleEmailChange}
                />
              </InputWrapper>
              {emailError && <div style={{ color: "red" }}>{emailError}</div>}
            </FieldContainer>

            <FieldContainer>
              <FiledTextWrapper>
                <FieldText>
                  {" "}
                  Phone Number <Span> *</Span>
                </FieldText>
              </FiledTextWrapper>
              <InputWrapper>
                <FieldInput
                  type="number"
                  placeholder="Number"
                  value={number}
                  onChange={(e) => handleNumberChange(e)}
                />
              </InputWrapper>
              {numberError && <div style={{ color: "red" }}>{numberError}</div>}
            </FieldContainer>

            <FieldContainer>
              <FiledTextWrapper>
                <FieldText>
                  Service Required <Span> *</Span>
                </FieldText>
              </FiledTextWrapper>
              <InputWrapper>
                <FieldSelect value={select} onChange={handleSelectChange}>
                  <Option value=""> -Select-</Option>
                  <Option value="mainland"> Mainland</Option>
                  <Option value="freezone">Freezone</Option>
                  <Option value="jobseeker">Job Seeker</Option>
                  <Option value="other">Other</Option>
                </FieldSelect>
              </InputWrapper>
              {selectError && <div style={{ color: "red" }}>{selectError}</div>}
            </FieldContainer>

            <FieldContainer>
              <FiledTextWrapper>
                <FieldText>
                  Tell us more about the business you are looking to start{" "}
                  <Span> *</Span>
                </FieldText>
              </FiledTextWrapper>
              <InputWrapper>
                <Textarea
                  rows="4"
                  cols="50"
                  placeholder="message"
                  value={message}
                  onChange={handleMessageChange}
                />
              </InputWrapper>
            </FieldContainer>

            <PrivacyTextWrapper>
              <PrivacyText>
                By clicking the submit button below, I hereby agree to and
                accept the <A href="#">Privacy Policy</A> &{" "}
                <A href="#">Terms & Conditions</A>{" "}
              </PrivacyText>
            </PrivacyTextWrapper>

            <ButtonWrapper>
              <BookButton onClick={HandleButton} disabled={!formValid}>
               
                {loading ? <Loader /> : "Book Now"}
              </BookButton>
            </ButtonWrapper>
           
          </FormContainer>
        </FormWrapper>
      </FormTextWrapper>
    </Root>
  );
}
