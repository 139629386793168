import React from 'react'

const Loader = () => {
  return (
   <div style={{padding:"0px", margin:"0"}}>
    Loading ...
   </div>
  )
}

export default Loader