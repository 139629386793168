import React from "react";
import styled from "styled-components";
import ServicesImage1 from "../../Images/Serviceinfo.jpg";
import Triangle from "../../Images/triangle.png";
import VisaServices from "../../Images/Pro-Visa-services.jpg";
import Ejari from "../../Images/Ejari.jpg";
import EServices from "../../Images/E-services.jpg";
import VatRegistration from "../../Images/VAT-registration.jpg";
import Etrader from "../../Images/E-trader.jpg";
import Commerciallicense from "../../Images/commercial-license.jpg"
import ServicesImage2 from "../../Images/servicesInfo2.jpg";
import { useNavigate } from "react-router-dom";

const Root = styled.div`
  padding: 60px 0;
`;
const Container = styled.div`
  width: 1160px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    padding: 0 20px;
    width: 100%;
  }
`;

const InfoSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  @media(max-width: 768px){
justify-content: center;
width: 100%;
align-items: center;
flex-direction: column;
  }
  ${(p) =>
    p.wrap &&
    `
    flex-direction: row-reverse;
    @media(max-width: 768px){
justify-content: center;
align-items: center;
flex-direction: column;
  }
  `};
`;
const ImageWrapper = styled.div`
  width: calc(50% - 5px);
  @media(max-width: 768px){
    width: 80%;
  }
  @media(max-width: 425px){
    width: 100%;
  }
`;
const Image = styled.img`
  width: 100%;
  object-fit: fit-content;
  height: 100%;
`;
const CardWrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: calc(50% - 5px);
  /* box-shadow: 1px 1px 1px 1px #0f5034; */
  border: solid 1px #0f5034;
  padding: 20px 60px;
  @media(max-width: 768px){
    padding: 20px 20px;
    width: 80%;
  }
  @media(max-width: 425px){
    width: 100%;
  }
`;
const HeadText = styled.div`
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #0d1117;
`;
const Text = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #363636;
   padding: 15px 0 15px 0;
`;
const Button = styled.button`
  background-color: #23744e;
    border: 1px solid #ffb300;
    padding: 15px 35px;
    color: #ffb300;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    position: relative;
`;
const ImageIcon = styled.img`
  position: absolute;
  height: 47.6px;
  top: 0;
  right: 0;
`;
export default function ServicesInfo() {
  const navigate = useNavigate();
  return (
    <Root>
      <Container>
        <InfoSection>
          <ImageWrapper>
            <Image src={ServicesImage1} />
          </ImageWrapper>
          <CardWrapper>
            <HeadText>Company Registration</HeadText>
            <Text>
            Dubai is renowned for its robust economy and simplicity of business formation. With Dubai Startup, you can significantly reduce the price of company registration in Dubai.
            </Text>
            <Button onClick={()=> navigate("/CompanyRegistration")}>
              Know More
              <ImageIcon src={Triangle} />
            </Button>
          </CardWrapper>
        </InfoSection>


        <InfoSection wrap>
          <ImageWrapper>
            <Image src={ServicesImage2} />
          </ImageWrapper>
          <CardWrapper>
            <HeadText>LLC Company Formation in Dubai</HeadText>
            <Text>
            Commercial business license in Dubai is straightforward under our supervision, making all the rules and regulations easy for you to understand.
            </Text>
            <Button onClick={()=> navigate("/LLCCompanyFormation")}>
              Know More
              <ImageIcon src={Triangle} />
            </Button>
          </CardWrapper>
        </InfoSection>

        <InfoSection >
          <ImageWrapper>
            <Image src={Commerciallicense} />
          </ImageWrapper>
          <CardWrapper>
            <HeadText>Commercial License</HeadText>
            <Text>
            In Dubai, ordinary and specialized traders can benefit from a commercial license. A commercial license can be issued to trade firms in Dubai that trade goods, commodities, and services. Opening a company in Dubai offers opportunities for networking and collaboration. Interested in getting one?
            </Text>
            <Button onClick={()=> navigate("/CommercialLicense")}>
              Know More
              <ImageIcon src={Triangle} />
            </Button>
          </CardWrapper>
        </InfoSection>


        <InfoSection wrap>
          <ImageWrapper>
            <Image src={VisaServices} />
          </ImageWrapper>
          <CardWrapper>
            <HeadText>PRO Visa Services</HeadText>
            <Text>
            Since the launch of Golden Visa services in UAE, businesses have been flocking towards Dubai with new innovative ideas. It is because such Visa provides a long-term residency program to boost the country's economy. Reap the benefits and grow your dream business with the right consultancy services. 
            </Text>
            <Button onClick={()=> navigate("/PROVisa")}>
              Know More
              <ImageIcon src={Triangle} />
            </Button>
          </CardWrapper>
        </InfoSection>


        <InfoSection >
          <ImageWrapper>
            <Image src={Ejari} />
          </ImageWrapper>
          <CardWrapper>
            <HeadText>Ejari Registration</HeadText>
            <Text>
            The Real Estate Regulatory Authority (RERA) implemented a system called Ejari to help manage the landlord-tenant relationship in Dubai. Tenancy agreements must be signed and registered with Ejari in order to rent a residential, commercial, or industrial property. Get your Ejari done too!
            </Text>
            <Button onClick={()=> navigate("/EjariRegistration")}>
              Know More
              <ImageIcon src={Triangle} />
            </Button>
          </CardWrapper>
        </InfoSection>


        <InfoSection wrap>
          <ImageWrapper>
            <Image src={EServices} />
          </ImageWrapper>
          <CardWrapper>
            <HeadText>E-services</HeadText>
            <Text>
            E-services are a helpful technology that aids in streamlining and organizing numerous administrative and corporate activities. Contact our team right away for additional details about starting an online store, working in the mainland, or free zones.
            </Text>
            <Button onClick={()=> navigate("/Eservices")}>
              Know More
              <ImageIcon src={Triangle} />
            </Button>
          </CardWrapper>
        </InfoSection>


        <InfoSection >
          <ImageWrapper>
            <Image src={VatRegistration} />
          </ImageWrapper>
          <CardWrapper>
            <HeadText>VAT Registration and VAT Consultancy</HeadText>
            <Text>
            For VAT registration reasons, it is crucial to ascertain which business operations are considered taxable supplies. To prevent paying late registration fees, it's also essential to make sure a business registers for VAT before the deadline.
            </Text>
            <Button onClick={()=> navigate("/VATRegistration")}>
              Know More
              <ImageIcon src={Triangle} />
            </Button>
          </CardWrapper>
        </InfoSection>


        <InfoSection wrap>
          <ImageWrapper>
            <Image src={Etrader} />
          </ImageWrapper>
          <CardWrapper>
            <HeadText>E-trader License</HeadText>
            <Text>
            An e-trader license is permission granted by the relevant authorities to start an online business. In Dubai, e-commerce permits are only issued by the Department of Economic Development (DED). You must submit the required paperwork and follow the approved procedure to apply for the specific license. 
            </Text>
            <Button onClick={()=> navigate("/EtraderLicense")}>
              Know More
              <ImageIcon src={Triangle} />
            </Button>
          </CardWrapper>
        </InfoSection>


       
      </Container>
    </Root>
  );
}
