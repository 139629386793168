import React from "react";
import styled from "styled-components";
import MapImage from '../../../Images/map.jpg';

const Root = styled.div`
  width: 500px;
  margin: 0 auto;
  padding-top:50px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Image = styled.img`
width:100%;
`;
export default function ViewMap() {
  return (
    <Root>
      <Image src={MapImage}/>
    </Root>
  );
}

