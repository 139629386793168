import React from "react";
import styled from "styled-components";
import Banner from "../../../../Images/service-inner-2.jpg";
import RightIcon from "../../../../Images/right.png";
const Root = styled.div`
  width: 1160px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 20px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
const LeftsideWrapper = styled.div`
  width: 50%;
  @media (max-width: 767px) {
   display: none;
  }
`;
const Image = styled.img`

`;
const RightsideWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  @media (max-width: 767px) {
    width: 100%;
  }
`;
const Text = styled.text`
  font-size: 42px;
  font-weight: bold;
  text-align: left;
  color: #0b0e13;

  ${(p) =>
    p.BoldText &&
    `
     @media(max-width:767px){
      font-size:30px;
     }
  `};

  ${(p) =>
    p.Paragraph &&
    `
    font-weight: lighter;
    font-family: Roboto;
  font-size: 18px;
  line-height: 1.33;
  text-align: left;
  color: #0b0e13;
  margin: 1rem 0;
  text-align: left;
  color: #0b0e13;
  `}
  ${(p) =>
    p.PointsText &&
    `
    font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
  text-align: left;
  color: #292e3c;
  padding-left: 1rem;
  width:100%;
  max-width:85%;
  @media(max-width:767px){
    font-size: 16px;
  }
  `}
`;
const PointsWrapper = styled.div`
  display: flex;
  padding: 10px 0;
`;
const IconWrapper = styled.div`
  width: 25px;
  height: 25px;
  background-color: #055d2d;
  border-radius: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Icon = styled.img``;

function WhyLiabilities(props) {
  return (
    <Root>
      <Wrapper>
        <LeftsideWrapper>
          <Image src={Banner} />
        </LeftsideWrapper>
        <RightsideWrapper>
          <Text BoldText>Why Dubai Startup?</Text>
          {/* <Text BoldText>(Limited Liability Company)?</Text> */}
          <Text Paragraph>
          Ejari is a regulatory organization created exclusively for managing lease agreements and renting in Dubai. Our team of business consultants is efficient in giving personalized service. You may require additional paperwork as it may involve several different tasks.  
          </Text>
          {/* <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>LLC formation is fast and easy</Text>
          </PointsWrapper> */}
          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            Second, the type of documents varies according to the chosen location and corporate structure. 
            </Text>
          </PointsWrapper>
          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
             
            We can assist you in setting up all necessary paperwork and approvals.

            </Text>
          </PointsWrapper>

          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            Our assistance also works to help you finalize your company's design before getting started with company registration.
            </Text>
          </PointsWrapper>

          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            Our way of doing things is so easy that it works best for residents and non-residents to run their businesses from anywhere.
            </Text>
          </PointsWrapper>
        </RightsideWrapper>
      </Wrapper>
    </Root>
  );
}

export default WhyLiabilities;
