import React from 'react'
import styled from 'styled-components'
import Triangle from "../Images/triangle.png";
import { useNavigate } from 'react-router-dom';

const Root = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding:60px 0px;
`;
const Button = styled.button`
 background-color: #23744e;
    border: 1px solid #ffb300;
    padding: 14px 35px;
    color: #ffb300;
    font-size: 16px;
    font-weight: 700;
    position: relative;
    cursor: pointer;
`;
const ImageIcon = styled.img`
  position: absolute;
  height: 47px;
  top: 0;
  right: -1px;
`;
const Text = styled.div`
margin-bottom:10px;
font-size: 18px;
color:#1c1c1c;
`;
const Div = styled.div`
margin-bottom:20px;
font-size:36px;
@media(max-width: 570px){
  font-size: 25px;
}
`;
export default function ContactUsSection() {
  const navigate = useNavigate();
  return (
    <Root>
           <Text>Did’t find and answer?</Text>
           <Div>Call us at: <span style={{color:"#175d3c", fontWeight: "700"}}>+971 56 607 4725</span></Div>
              <Button onClick={()=> navigate("/ContactUs")}>
              Contact Us
              <ImageIcon src={Triangle} />
            </Button>
    </Root>
  )
}
