import React from 'react';
import styled from 'styled-components';
import ContactUsSection from '../../Components/ContactUsSection';
import Navigation from '../../Components/Navigation';

const Root = styled.div`

`;
const Container = styled.div`
  width: 1160px;
  display: flex;
  margin:0 auto;
  justify-content: right;
  @media (max-width: 1200px) {
    padding: 0px 20px;
    width: 100%;
  }
`;
const TextWrapper = styled.div`
margin: 3rem 0;
display: flex;
flex-direction: column;
justify-content: center;

`;
const BoldText = styled.text`
font-size: 24px;
font-weight: 600;
padding: 1rem 0 0 0;
`;
const Text = styled.p`
    line-height: 20px;
`;
const Span = styled.span`
font-size: 16px;
font-weight: 600;
`;
const NavigationWrapper = styled.div`
  background-color: #23744e;
`;
function Termsofuse(props) {
    return (
        <Root>
            <NavigationWrapper>
        <Navigation />
      </NavigationWrapper>
        <Container>
        <TextWrapper>
            <BoldText>Dubai Startup Terms and Conditions </BoldText>
            <Text>You must read and comprehend our terms and conditions as they are published on our website because they are frequently modified. Dubai Startup reserves the right to amend or replace the agreement at any time without notifying the clients.</Text>
            
            <Text>Users and customers of the website are responsible for reviewing any changes. It is acknowledged that clients have read, comprehended, and accepted the terms and conditions by signing the proposal when the business was established. Clients will routinely check for updates by visiting our official website and communications.</Text>


            <BoldText>Internet usage</BoldText>
            <Text>Your use of this website, including any pages within it, is subject to the Dubai Startup terms and conditions outlined in this document. You accept all terms and conditions contained in this website by using this website, and these terms are fully applicable to your use of this website. You must adhere to the rules of using the website and not use this website for any illegal means. </Text>

            <BoldText>Acceptance of Terms By Using Our Website.</BoldText>
            <Text>Hereby, you accept that you have read, comprehended, and given consent to the terms of this policy by using this website. You may not use our website if you disagree with these terms.</Text>

            <BoldText>Subject To Additional Terms</BoldText>
            <Text>Please be aware that you may also be subject to other terms. Hereby, our business terms and conditions and software license may also apply to you if you work with us to provide products.</Text>

            <BoldText>We Reserve All The Right To Modify The Terms Of This Policy</BoldText>
            <Text>We periodically change the content of the website. Please review these terms each time you want to use our site to ensure you are familiar with the current ones. The website may be suspended or removed by us at any time. For administrative and business purposes, we reserve the right to temporarily stop, remove, or restrict access to all or any portion of our website.</Text>

            <BoldText>How to Use the Content on Our Website</BoldText>
            <Text>All intellectual property rights and the content published on our website therein belong to us or are under license. Global copyright laws and treaties protect all of the content created on our website, and these rights are all reserved.</Text>

            <Text>Any page(s) from our website may be printed once for personal use, downloaded in excerpts, and you are free to call people in your organization's attention to information placed there.</Text>

            <Text>You may not change the printed or downloaded contents in any way, and you may not use any graphics, photos, video, or audio sequences apart from any printed or downloaded materials.</Text>


            <BoldText>How to Reach Dubai Startup?</BoldText>
            <Text><Span>https://www.DubaiStartup.com </Span>is a site operated by Dubai Startup LLC (referred to on these terms and conditions as “us” or “we”). We are a ……… Limited Liability Company registered in the UAE with license number ……... Our registered address is………., United Arab Emirates. For any inquiry and feedback, please email us at …….. </Text>
            
         </TextWrapper>
        </Container>
        <ContactUsSection />
        </Root>
    );
}

export default Termsofuse;