import React from 'react';
import BussinessModel from './BussinessModel';
import CompanySection from './CompanySection';
import HeroSection from './HeroSection';
import Inquiry from './Inquiry';
import Ourclient from './Ourclient';
import Services from './Services';
import StartBussinessStep from './StartBussinessStep';

function Home(props) {
    return (
        <div>
            <HeroSection/>
            <StartBussinessStep/>
            <Services/>
            <Ourclient/>
            <BussinessModel/>
            <Inquiry/>
            <CompanySection/>
        </div>
    );
}

export default Home;