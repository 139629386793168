import React from "react";
import styled from "styled-components";
import Banner from "../../../../Images/Visa3rdsection.png";
import RightIcon from "../../../../Images/right.png";

const Root = styled.div`
  padding: 5rem 0 5rem 0;
  width: 1160px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 4rem 20px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  @media(max-width:767px){
    flex-direction: column;
    align-items: center;
  }
`;
const LeftsideWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content:center;
  align-items: center;
  @media(max-width:767px){
   display: none;
  }
`;
const Image = styled.img`
width:100%;
`;
const RightsideWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  @media(max-width:767px){
   width:100%;
  }
`;
const Text = styled.text`
  font-size: 42px;
  font-weight: bold;
  text-align: left;
  color: #0b0e13;
  ${(p) => p.BoldText && `
    @media(max-width:767px){
      font-size: 30px;
    }
  `}
  ${(p) =>
    p.Paragraph &&
    `
    font-weight: lighter;
    font-family: Roboto;
  font-size: 18px;
  line-height: 1.33;
  text-align: left;
  color: #0b0e13;
  margin: 1rem 0;
  text-align: left;
  color: #0b0e13;
  @media(max-width:767px){
      font-size: 16px;
      margin:0;
    }
  `}
  ${(p) =>
    p.PointsText &&
    `
    font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
  text-align: left;
  color: #292e3c;
  padding-left: 1rem;
  width:100%;
  `}
`;
const PointsWrapper = styled.div`
  display: flex;
  padding: 15px 0;
`;
const IconWrapper = styled.div`
  width: 25px;
  height: 25px;
  background-color: #055d2d;
  border-radius: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Icon = styled.img``;

function RequiredDocumentation(props) {
  return (
    <Root>
      <Wrapper>
        <LeftsideWrapper>
          <Image src={Banner} />
        </LeftsideWrapper>
        <RightsideWrapper>
          <Text BoldText>Importance of Visa in Dubai for Business</Text>
    
          <Text Paragraph>
          To lawfully dwell or work in Dubai and other emirates, any nationalities different than those of the UAE and the GCC must obtain a UAE residency visa. Additionally, a resident visa is required to apply for a driver's license, register a car, request a PO Box, or purchase real estate.
          </Text>
          <Text Paragraph>        
            <Text Paragraph>
            The following services come as part of our PRO visa services: 
          </Text>
          </Text>
          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>Visa Cancellation and Visa Renewal</Text>
          </PointsWrapper>
          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
          Family Visa Sponsorship
            </Text>
          </PointsWrapper>
          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            VIP Medical Chaperoned Services
            </Text>
          </PointsWrapper>

          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            Company Formation
            </Text>
          </PointsWrapper>

          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            Trademark or Copyright Registration
            </Text>
          </PointsWrapper>

          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            Company Liquidation or De-Registration of Companies
            </Text>
          </PointsWrapper>

          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            Corporate Bank Account Opening
            </Text>
          </PointsWrapper>
          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            Partner or Investor Visa Processing
            </Text>
          </PointsWrapper>

        </RightsideWrapper>
      </Wrapper>
    </Root>
  );
}

export default RequiredDocumentation;
