import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
padding: 8rem 0;
@media(max-width:767px){
  padding: 5rem 0; 
}
`;
const TextWrapper = styled.div` 
width: 1160px;
margin: 0 auto;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
@media(max-width:1200px){
width: 100%;
padding: 0 20px;
}
`;
const Text = styled.text`
 font-size: 22px;
  font-weight: 500;
  text-align: center;
  color: #06613a;

  ${(p)=> p.BoldText && `
    font-family: Roboto;
  font-size: 42px;
  font-weight: bold;
  line-height: 2.12;
  text-align: center;
  color: #0b0e13;
  @media(max-width:767px){
    font-size:30px;
    margin:20px 0px;
    line-height:1;
  }
  `}
  ${(p)=> p.Paragraph && `
   font-family: Roboto;
  font-size: 20px;
  line-height: 1.7;
  text-align: center;
  color: #0b0e13;
  @media(max-width:767px){
    font-size: 16px;
    line-height: 1.5;
  }
  `}
`;

function Liabilities(props) {
    return (
        <Root>
         <TextWrapper>
         <Text>Employ the Right Counsel for Company Registration in Dubai </Text>
         <Text BoldText> How to Start a Company Registration in Dubai?</Text>
         <Text Paragraph> To launch a business in Dubai, you must possess all the required paperwork. If you have a valid and comprehensive set of papers, it would be convenient for you to launch your business in Dubai. But most budding startups and entrepreneurs need to figure out what to do next for the paperwork, albeit the formality. It becomes easy if you have the proper counsel beside you to guide you at this vital stage.  </Text>
         </TextWrapper>
        </Root>
    );
}

export default Liabilities;