import React from "react";
import styled from "styled-components";
import BackgroundWrapper from "../../../../Images/EServicesBackgroundWrapper.jpg";
import TraingleImage from "../../../../Images/triangle.png";
import { useNavigate  } from "react-router-dom";

const Root = styled.div`
  background-image: url(${BackgroundWrapper});
  background-size: 100% contain;
  background-repeat: no-repeat;
  background-position:right;
`;
const Wrapper = styled.div`
  width: 1160px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 20px;
  }
`;
const LeftsideWrapper = styled.div`
  justify-content: center;
  align-items: start;
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 7.8rem 0;
  @media(max-width:1000px){
    width: 70%;
  }
  @media(max-width:767px){
    width: 100%;
    padding: 6rem 0;
  }
`;

const Text = styled.text`
  width: 90%;
  font-family: Roboto;
  font-size: 26px;
  font-weight: 500;
  text-align: left;
  color: #06613a;

  ${(p) =>
    p.BoldText &&
    `
   font-size: 69.5px;
  font-weight: bold;
  line-height: 1.12;
  text-align: left;
  color: #0a0f22;
  margin: 28px 0px;
  @media(max-width:1000px){
    font-size: 45px;
  }
  @media(max-width:767px){
    font-size: 35px;
    margin: 14px 0px;
  }

  `}

  ${(p) =>
    p.ParagraphText &&
    `
    font-family: Roboto;
  font-size: 20px;
  line-height: 1.7;
  text-align: left;
  color: #0a0f22;
  padding-bottom:3rem;

  `}
`;
const ButtonWrapper = styled.div`
  /* margin: 50px 0 0 0; */
`;
const Button = styled.button`
  background-color: #23744e;
  border: 1px solid #ffb300;
  padding: 15px 30px;
  color: #ffb300;
  position: relative;
  font-weight: 600;
  cursor: pointer;
  font-size: 16px;
`;
const Image = styled.img`
  position: absolute;
  height: 48.7px;
  top: 0;
  right: 0;
`;
function Hero(props) {
  const navigate = useNavigate();
  return (
    <Root>
      <Wrapper>
        <LeftsideWrapper>
          <Text>Stay updated and instant everything with e-services.</Text>
          <Text BoldText>E-services in Dubai</Text>
          <Text ParagraphText>
          E-services are a helpful technology that aids in streamlining and organizing numerous administrative and corporate activities. Contact our team right away for additional details about starting an online store, working in the mainland, or free zones.
          </Text>

          <ButtonWrapper>
            <Button onClick={()=> navigate("/ContactUs")}>
            Book an appointment<Image src={TraingleImage} />
            </Button>
          </ButtonWrapper>
        </LeftsideWrapper>
      </Wrapper>
    </Root>
  );
}

export default Hero;
