import React from "react";
import styled from "styled-components";
import Banner from "../../../../Images/Eservices3rdsection.png";
import RightIcon from "../../../../Images/right.png";

const Root = styled.div`
  padding: 5rem 0 5rem 0;
  width: 1160px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 4rem 20px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  @media(max-width:767px){
    flex-direction: column;
    align-items: center;
  }
`;
const LeftsideWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content:center;
  align-items: center;
  @media(max-width:767px){
   display: none;
  }
`;
const Image = styled.img`
width:100%;
`;
const RightsideWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  @media(max-width:767px){
   width:100%;
  }
`;
const Text = styled.text`
  font-size: 42px;
  font-weight: bold;
  text-align: left;
  color: #0b0e13;
  ${(p) => p.BoldText && `
    @media(max-width:767px){
      font-size: 30px;
    }
  `}
  ${(p) =>
    p.Paragraph &&
    `
    font-weight: lighter;
    font-family: Roboto;
  font-size: 18px;
  line-height: 1.33;
  text-align: left;
  color: #0b0e13;
  margin: 1rem 0;
  text-align: left;
  color: #0b0e13;
  @media(max-width:767px){
      font-size: 16px;
      margin:0;
    }
  `}
  ${(p) =>
    p.PointsText &&
    `
    font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
  text-align: left;
  color: #292e3c;
  padding-left: 1rem;
  width:100%;
  `}
`;
const PointsWrapper = styled.div`
  display: flex;
  padding: 15px 0;
`;
const IconWrapper = styled.div`
  width: 25px;
  height: 25px;
  background-color: #055d2d;
  border-radius: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Icon = styled.img``;

function RequiredDocumentation(props) {
  return (
    <Root>
      <Wrapper>
        <LeftsideWrapper>
          <Image src={Banner} />
        </LeftsideWrapper>
        <RightsideWrapper>
          <Text BoldText>Importance of e-license in Dubai </Text>
    
          <Text Paragraph>
          There is a different set of documentation for each firm in Dubai because there are several types of enterprises in which our services can help you get an e-trader license on time so that you’ll be empowered to take your business ideas to the online world. 
          </Text>
          <Text Paragraph>        
            <Text Paragraph>
            Our services can help you with business setup in Dubai and the following benefits:
          </Text>
          </Text>
          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>	Startups in Dubai can conduct business operations online and across social networking accounts thanks to the DED Trader license. </Text>
          </PointsWrapper>
          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            	The license attempts to direct traders in the appropriate direction as they make their way into the business. 
            </Text>
          </PointsWrapper>
          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            	Dubai Economy aims to regulate and improve the simplicity of conducting business electronically via the DED Trader license. 
            </Text>
          </PointsWrapper>

          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            	Wherever you are, the DED Trader license is electronically granted. The ability to conduct business in Dubai and other Emirates is one advantage of obtaining a DED trading license. 
            </Text>
          </PointsWrapper>

          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            	Dubai offers it's business owners three significant options for setting up their operations within the Emirate: on the mainland, offshore, or both. 
            </Text>
          </PointsWrapper>

          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            	To obtain a trade license, the investor or business owner must select the right business consultancy services for their enterprise.
            </Text>
          </PointsWrapper>
        </RightsideWrapper>
      </Wrapper>
    </Root>
  );
}

export default RequiredDocumentation;
