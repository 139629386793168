export const Data = [
    {
        id:1,
        question:'What are the business opportunities in Dubai for investors?',
        ans:'With cutting-edge infrastructure and a first-rate business climate, Dubai has become the top regional commercial centre. For investors, Dubai is an ideal place to invest in business due to its strong economic position.'
    },
    {
        id:2,
        question:'How long does the registration and licensing process take?',
        ans:'The major problem people need to learn is knowing the procedure of company registration in Dubai. We proactively assist you in supporting your venture by removing all the hurdles in the way of starting the business. Get comprehensive services to speed up the process.'
    },
    {
        id:3,
        question:'How to start your business in dubai?',
        ans:'Once you decide which legal type of business entity you want to set up in Dubai, the process is pretty simple. Just get in touch with us to get quick approvals, trading licenses etc., to streamline the business registration process in Dubai. We can also help you find a sponsor and strategic office location within your budget.    '
    },
    {
        id:4,
        question:'Which small business is best in Dubai?',
        ans:'Dubai is the ideal base for multinationals due to its advantageous location as well as for small business due to its sophisticated free business environment. In this regard, there are not one but many opportunities for small businesses to flourish. Share your business idea, and we will help your dream come true. '
    },
    {
        id:5,
        question:'What business to start in dubai without investment?',
        ans:'If you are looking for low-cost business ideas in Dubai, you can go for online businesses because businesses in Dubai without investment are not easy. Nevertheless, we have the best business ideas in Dubai with a low-key investment and great many potentials. To know about it, get in touch with us today! '
    },
    {
        id:6,
        question:'What is the cost in setting up a business in Dubai?',
        ans:'Since Dubai is the ideal base for multinationals and small businesses, the cost varies due to the company nature, location, environment etc. factors. We are here to help you at each and every course with a tailored business solution.  '
    },
    {
        id:7,
        question:'How many tasks can I do under a single license in mainland?',
        ans:'Mainland companies can practice any industrial, commercial, trading and professional activities, so the tasks depend on the nature of your business. Let us know your specific needs, and we can get special approvals from relevant authorities that regulate those activities.'
    },
]