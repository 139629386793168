import React from 'react';

function Blog(props) {
    return (
        <div>
            Blog
        </div>
    );
}

export default Blog;