import React from 'react';

function OurConcept(props) {
    return (
        <div>
            OurConcept
        </div>
    );
}

export default OurConcept;