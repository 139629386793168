import React from "react";
import styled from "styled-components";
import Banner from "../../../../Images/VAT3rdsection.png";
import RightIcon from "../../../../Images/right.png";

const Root = styled.div`
  padding: 5rem 0 5rem 0;
  width: 1160px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 4rem 20px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  @media(max-width:767px){
    flex-direction: column;
    align-items: center;
  }
`;
const LeftsideWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content:center;
  align-items: center;
  @media(max-width:767px){
   display: none;
  }
`;
const Image = styled.img`
width:100%;
`;
const RightsideWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  @media(max-width:767px){
   width:100%;
  }
`;
const Text = styled.text`
  font-size: 42px;
  font-weight: bold;
  text-align: left;
  color: #0b0e13;
  ${(p) => p.BoldText && `
    @media(max-width:767px){
      font-size: 30px;
    }
  `}
  ${(p) =>
    p.Paragraph &&
    `
    font-weight: lighter;
    font-family: Roboto;
  font-size: 18px;
  line-height: 1.33;
  text-align: left;
  color: #0b0e13;
  margin: 1rem 0;
  text-align: left;
  color: #0b0e13;
  @media(max-width:767px){
      font-size: 16px;
      margin:0;
    }
  `}
  ${(p) =>
    p.PointsText &&
    `
    font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
  text-align: left;
  color: #292e3c;
  padding-left: 1rem;
  width:100%;
  `}
`;
const PointsWrapper = styled.div`
  display: flex;
  padding: 15px 0;
`;
const IconWrapper = styled.div`
  width: 25px;
  height: 25px;
  background-color: #055d2d;
  border-radius: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Icon = styled.img``;

function RequiredDocumentation(props) {
  return (
    <Root>
      <Wrapper>
        <LeftsideWrapper>
          <Image src={Banner} />
        </LeftsideWrapper>
        <RightsideWrapper>
          <Text BoldText>Requirements for VAT Registration in Dubai </Text>
    
          <Text Paragraph>
          The Federal Tax Authority must receive various documents as part of the VAT registration process (FTA). The VAT statute specifies thresholds for both required and optional VAT registration. 
          </Text>
          <Text Paragraph>        
            <Text Paragraph>
            The following papers would be necessary for the purpose of VAT in Dubai:
          </Text>
          </Text>
          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>The organization's business permit (copy after the trade or commercial license)</Text>
          </PointsWrapper>
          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            Copies of the owner's and other shareholders' passports
            </Text>
          </PointsWrapper>
          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            The company's memorandum, articles of association, and contact information for the firm's representative.
            </Text>
          </PointsWrapper>

          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            Evidence of the company's registered office in the United Arab Emirates
            </Text>
          </PointsWrapper>

          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            Details regarding the business's bank account (IBAN included)
            </Text>
          </PointsWrapper>

          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            An estimate of the company's anticipated revenue, turnover, and expenses for the 30 days after VAT registration,
            </Text>
          </PointsWrapper>

          
          <PointsWrapper>
            <IconWrapper>
              <Icon src={RightIcon} />
            </IconWrapper>
            <Text PointsText>
            Where applicable, the details of its import-export operations 
            </Text>
          </PointsWrapper>
        </RightsideWrapper>
      </Wrapper>
    </Root>
  );
}

export default RequiredDocumentation;
