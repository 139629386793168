import React from 'react'
import HeroSection from './HeroSection'
import ContactUsSection from '../../Components/ContactUsSection'
import ServicesInfo from './ServicesInfo'

export default function Services() {
  return (
    <div>
        <HeroSection/>
        <ServicesInfo/>
        <ContactUsSection/>
    </div>
  )
}
