import React, { useState } from "react";

export const StateManagementContext = React.createContext();

export const StateManagementProvider = ({ children }) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [showNvigation, setShowNavigation] = useState(false);
  return (
    <StateManagementContext.Provider
      value={{
        showDropDown,
        setShowDropDown,
        showNvigation,
        setShowNavigation,
      }}
    >
      {children}
    </StateManagementContext.Provider>
  );
};
