import React from "react";
import styled from "styled-components";
import Icon1 from "../../Images/num-icon1.png";
import Icon2 from "../../Images/num-icon2.png";
import Icon3 from "../../Images/num-icon3.png";
import Icon4 from "../../Images/num-icon4.png";
import ProfileImage from "../../Images/tetimonials-img.png";
import ProfileImage2 from "../../Images/tetimonials-img2.png";
import ProfileImage3 from "../../Images/tetimonials-img3.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";


const Root = styled.div`
  background-color: #edf2f5;
  padding: 60px 0;
`;
const Container = styled.div`
  width: 1160px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    padding: 0px 20px;
    width: 100%;
  }
`;
const CardItem = styled.div`
  width: calc(25% - 20px);
  background-color: #fff;
  box-shadow: 0 0 38px 0 rgba(0, 0, 0, 0.09);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  @media(max-width: 768px){
width: 100%;
margin-bottom: 20px;
  }
`;
const CardSection = styled.div`
  display: flex;
  justify-content: space-between;
  @media(max-width: 768px){
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;
const Icon = styled.img`
  width: 50px;
`;
const Data = styled.div`
  font-size: 60px;
  font-weight: 900;
  margin: 15px 0;
`;
const ButtonSection = styled.div`
  background-color: #0b9e5f;
  width: 100%;
  text-align: center;
  padding: 18px 0;
  border-radius: 0px 0px 20px 20px;
  color: #fff;
`;
const Heading = styled.div`
  font-size: 46px;
  text-align: center;
  margin-top: 70px;
`;
const SliderCard = styled.div`
  width: 600px;
  margin: 0 auto;
  background-color: #fff;
  /* box-shadow: 0 0 49px 0 rgba(0, 0, 0, 0.43); */
  padding: 0px 10px;
  margin-top: 30px;
  position: relative;
  min-height: 300px;
  @media(max-width: 768px){
width: 100%;
  }
`;
const InnerWrapper = styled.div`
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
  width: 100%;
  display: flex;
  min-height: 320px;
  @media(max-width: 768px){
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  width: 40%;
`;
const ContentWrapper = styled.div`
  text-align: center;
  padding: 60px 20px 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;
  @media(max-width: 768px){
    padding: 20px 0 0 0;
    width: 100%;
  }
`;
const Image = styled.img`
  height: fit-content;
  width: 100%;
  width: 100%;
`;
const TextWrapper = styled.div`
  font-size: 17px;
`;
const InfoWrapper = styled.div`
  padding-bottom: 20px;
`;
const Name = styled.div``;
const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  ${(p) =>
    p.right &&
    `
  right:${p.right};
`};
  ${(p) =>
    p.left &&
    `
  left:${p.left};
`};
  font-size: 40px !important;
  color: #0b9e5f;
`;
const Carousel = styled(OwlCarousel)`
  width: 600px;
  margin: 0 auto;
  @media(max-width: 769px){
width: 100%;
  }
  & .owl-prev {
    left: -20px !important;
    top: 45%;
    position: absolute;
    z-index: 10px;
    font-size: 40px !important;
    color: #0b9e5f;
  }
  & .owl-next {
    right: -20px !important;
    top: 45%;
    position: absolute;
    z-index: 10px;
    font-size: 40px !important;
    color: #0b9e5f;
  }
`;
const Card = ({ icon, data, buttonText }) => {
  return (
    <CardItem>
      <Icon src={icon} />
      <Data>{data}</Data>
      <ButtonSection>{buttonText}</ButtonSection>
    </CardItem>
  );
};
export default function Ourclient() {
  const options = {
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    navText: [
      `
      <div class="imgsection"><img class="imgWrapper" src="./arrow-left.png"/></div>
      `,
      `
      <div class="imgsection"><img class="imgWrapper" src="./arrow-right.png"/></div>
      `,
    ],
    smartSpeed: 1000,
    loop: true,
    responsive: {
      0: {
        items: 1,
      },
      500: {
        items: 1,
      },
      900: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  };
  return (
    <Root>
      <Container>
        <CardSection>
          <Card icon={Icon1} data="900+" buttonText="New Licencing" />
          <Card icon={Icon2} data="1200+" buttonText="Licence Renew" />
          <Card icon={Icon3} data="148+" buttonText="Global Presence" />
          <Card icon={Icon4} data="5653+" buttonText="Customer Served" />
        </CardSection>
        <Heading>Our clients know best</Heading>
        <Carousel {...options}>
          {/* <SliderCard>
            <InnerWrapper>
              <ImageWrapper>
                <Image src={ProfileImage} />
              </ImageWrapper>
              <ContentWrapper>
                <TextWrapper>
              "I first contacted the team because of pressing deadlines and uncertainty over the procedure for establishing a firm. Right away, Dubai Startup put my mind at ease. My main point of contact was designated as Som, and he has provided exceptional customer service. His understanding of the visa application procedure, his ability to handle my complicated situation, and the time he took to address my questions and worries have been incredible. Thanks to the team's exceptional service. I will suggest the business to my friends!"
                </TextWrapper>
                <InfoWrapper>
                  <Name style={{ color: "#0b9e5f" }}>-Ali Rajan</Name>
                  <Name>CEO at Project Delta Co</Name>
                </InfoWrapper>
              </ContentWrapper>
            </InnerWrapper>
          </SliderCard> */}
{/* 
          <SliderCard>
            <InnerWrapper>
              <ImageWrapper>
                <Image src={ProfileImage} />
              </ImageWrapper>
              <ContentWrapper>
                <TextWrapper>
                  “Dubai Startup has really impressed me with their polite staff and quick services I was really pleased with their professional level of service. All of my inquiries and questions were skillfully handled from the start. The staff assisted me in overcoming significant obstacles, particularly during the application for a passport and a visa. I appreciate all the help.”
                </TextWrapper>
                <InfoWrapper>
                  <Name style={{ color: "#0b9e5f" }}>-Azimul Haque</Name>
                 
                </InfoWrapper>
              </ContentWrapper>
            </InnerWrapper>
          </SliderCard> */}

          <SliderCard>
            <InnerWrapper>
              <ImageWrapper>
                <Image src={ProfileImage2} />
              </ImageWrapper>
              <ContentWrapper>
                <TextWrapper>
                  “Our registration and getting Visa for the business setup with Dubai Startup have all gone smoothly. I did not have to worry about the rules as it was well taken care of by the team, my documents were on point, and that made the rest of the process easy. Truly admirable service. ”
                </TextWrapper>
                <InfoWrapper>
                  <Name style={{ color: "#0b9e5f" }}>- Stuart Shade </Name>
                  <Name style={{ color: "#0b9e5f" }}>Director, Floxypay</Name>
                </InfoWrapper>
              </ContentWrapper>
            </InnerWrapper>
          </SliderCard>
          
          <SliderCard>
            <InnerWrapper>
              <ImageWrapper>
                <Image src={ProfileImage3} />
              </ImageWrapper>
              <ContentWrapper>
                <TextWrapper>
                  “Thank you Dubai Startup for your assistance in starting my business in Dubai. I appreciate your quick response and effective strategy very much. Your suggestions have been excellent. Som performed a fantastic job expediting the procedure for my visa. ”
                </TextWrapper>
                <InfoWrapper>
                  <Name style={{ color: "#0b9e5f" }}>-Jolly mathur</Name>
                  <Name style={{ color: "#0b9e5f" }}>Director , BitQuest</Name>
                </InfoWrapper>
              </ContentWrapper>
            </InnerWrapper>
          </SliderCard>
        </Carousel>
      </Container>
    </Root>
  );
}
