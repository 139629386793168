import styled from 'styled-components';

export const Root = styled.div`
  display:flex;
  justify-content:space-between;
  padding:0 30px;
  flex-wrap: wrap;
  @media(max-width:720px){
      flex-direction:column;
      text-align:left;
  }
`;
export const HeadingWrapper = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:top;
`;
export const Heading = styled.div`
color: #01c26f;
font-size: 22px;
padding-bottom: 5px; 
font-weight:500;
@media(min-width:720px){
    margin-top:20px;
}
@media(max-width:720px){
    margin-bottom:10px;
    font-size: 16px;
}
`;

export const LinkWrapper = styled.div`
  display:flex;
  flex-direction:column;
  @media(max-width:720px){
      width:100%;
      justify-content:left;
  }
 
`;

export const Link = styled.a`
color: #b6e1cd;
font-size: 15px;
line-height: 27px;
text-decoration:none;
font-weight:300;
cursor: pointer;
:hover{
    color: #b6e1cd;
}
`;

export const DesktopView = styled.div`
display:none;
  @media(min-width:720px){
    display:flex;
    flex-direction:column;
    justify-content:left;
  }
`;
export const MobileView = styled.div`
  display:none;
  @media(max-width:720px){
      display:flex;
      flex-direction:column;
      margin-top:-10px;
      margin-bottom:20px;
  }
`;
export const Icon = styled.i`
color: #01c26f;
cursor:pointer;
font-weight:bold;
font-size:16px;
@media(min-width:720px){
    display:none;
}
`;