import React from "react";
import styled from "styled-components";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// import Icon1 from "../../Images/service-icon1.png";
import UserInfoSection from "./UserInfoSection";
import ServicesIcon1 from "../../Images/service-icon1.png";
import ServicesIcon2 from "../../Images/service-icon2.png";
import ServicesIcon3 from "../../Images/service-icon3.png";
import ServicesIcon4 from "../../Images/service-icon4.png";
import ServicesIcon5 from "../../Images/service-icon5.png";
import ServicesIcon6 from "../../Images/service-icon6.png";
import ServicesIcon7 from "../../Images/service-icon7.png";
import ServicesIcon8 from "../../Images/service-icon8.png";
import { useNavigate } from "react-router-dom";

const Data = [
  {
    text: `Dubai is renowned for its robust economy and simplicity of business formation. With Dubai Startup, you can significantly reduce the price of company registration in Dubai.`,
  },
  {
    text:`
    Commercial business license in Dubai is straightforward under our supervision, making all the rules and regulations easy for you to understand.
    `
  },
  {
    text:`
    Since the launch of Golden Visa services in UAE, businesses have been flocking towards Dubai with new innovative ideas. It is because such Visa provides a long-term residency program to boost the country's economy. Reap the benefits and grow your dream business with the right consultancy services. 
    `
  },
  {
    text:`
    The Real Estate Regulatory Authority (RERA) implemented a system called Ejari to help manage the landlord-tenant relationship in Dubai. Tenancy agreements must be signed and registered with Ejari in order to rent a residential, commercial, or industrial property. Get your Ejari done too!
    `
  },
  {
    text:`
    For VAT registration reasons, it is crucial to ascertain which business operations are considered taxable supplies. To prevent paying late registration fees, it's also essential to make sure a business registers for VAT before the deadline.
    `
  }
];
const Root = styled.div``;
const Container = styled.div`
  width: 1160px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    padding: 0 20px;
    width: 100%;
  }
`;
const Heading = styled.div`
  font-size: 46px;
  margin-bottom: 150px;
  padding-left: 200px;
  @media (max-width: 1000px) {
    padding-left: 100px;
  }
  @media (max-width: 800px) {
    padding-left: 50px;
  }
  @media (max-width: 767px) {
    padding: 20px;
  }
`;
const InfoSection = styled.div`
  background-image: linear-gradient(to top, #000, #01f183);
  padding-bottom: 80px;
`;

const CardSection = styled.div`
  position: relative;
  top: -100px;
  padding-left: 200px;
  padding-top: 10px;
  @media (max-width: 1000px) {
    padding-left: 100px;
  }
  @media (max-width: 800px) {
    padding-left: 50px;
  }
  @media (max-width: 767px) {
    padding: 20px;
  }
`;
const Card = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 1px 1px 5px 1px rgb(0 0 0 / 13%);
  background-color: #fff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 370px;
`;
const HeadText = styled.div`
    font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #181e28;
  padding-top: 10px;
`;
const Carousel = styled(OwlCarousel)`
  & .owl-prev {
    left: -40px !important;
    top: 40%;
    position: absolute;
    z-index: 10px;
    @media (max-width: 767px) {
      left: 30px !important;
    }
  }
  & .owl-next {
    right: 0px !important;
    top: 40%;
    position: absolute;
    z-index: 10px;
    @media (max-width: 767px) {
      right: 30px !important;
    }
  }
  & .owl-stage-outer{
    padding:6px 0px;
  }
`;
const Icon = styled.img`
  width: 60px;
  max-width: 72px;
  margin: 0 auto;
`;
const Text = styled.div`
  padding-top: 15px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #363636;
`;
const LinkText = styled.div`
font-size: 18px;
font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #305847;
  cursor: pointer;
`;
export default function Services() {
  const options = {
    margin: 20,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    navText: [
      `<div class="arrow">
        <i class="fa-solid fa-angle-left"></i>
        </div>`,
      `
        <div class="arrow">
        <i class="fa-solid fa-angle-right"></i>
        </div>`,
    ],
    smartSpeed: 1000,
    loop: true,
    responsive: {
      0: {
        items: 1,
      },
      500: {
        items: 1,
      },
      550: {
        items: 1,
      },
      700: {
        items: 2,
      },
      900: {
        items: 3,
      },
      1200: {
        items: 3,
      },
      1500: {
        items: 4,
      },
    },
  };
  const navigate = useNavigate();
  return (
    <Root>
      <Container>
        <Heading>Our Services</Heading>
      </Container>
      <InfoSection>
        <CardSection>
          <Carousel {...options}>
            <Card>
              <div>
                <Icon src={ServicesIcon1} />
                <HeadText>Company Registration</HeadText>
                <Text>{Data[0].text.slice(0,180)}...</Text>
              </div>
              <LinkText onClick={()=>navigate("/CompanyRegistration")}>
                Get started <i class="fa-solid fa-arrow-right-long"></i>
              </LinkText>
            </Card>

            <Card>
              <div>
                <Icon src={ServicesIcon2} />
                <HeadText>LLC Company Formation</HeadText>
                <Text>{Data[1].text.slice(0,180)}...</Text>
              </div>
              <LinkText onClick={()=>navigate("/LLCCompanyFormation")}>
                Get started <i class="fa-solid fa-arrow-right-long"></i>
              </LinkText>
            </Card>

            <Card>
              <div>
                <Icon src={ServicesIcon3} />
                <HeadText>Commercial License</HeadText>
                <Text>{Data[0].text.slice(0,180)}...</Text>
              </div>
              <LinkText onClick={()=>navigate("/CommercialLicense")}>
                Get started <i class="fa-solid fa-arrow-right-long"></i>
              </LinkText>
            </Card>

            <Card>
              <div>
                <Icon src={ServicesIcon4} />
                <HeadText>PRO Visa</HeadText>
                <Text>{Data[2].text.slice(0,180)}...</Text>
              </div>
              <LinkText onClick={()=>navigate("/PROVisa")}>
                Get started <i class="fa-solid fa-arrow-right-long"></i>
              </LinkText>
            </Card>

            <Card>
              <div>
                <Icon src={ServicesIcon5} />
                <HeadText>Ejari Registration</HeadText>
                <Text>{Data[3].text.slice(0,180)}...</Text>
              </div>
              <LinkText onClick={()=>navigate("/EjariRegistration")}>
                Get started <i class="fa-solid fa-arrow-right-long"></i>
              </LinkText>
            </Card>

            <Card>
              <div>
                <Icon src={ServicesIcon6} />
                <HeadText>E-services</HeadText>
                <Text>
                {Data[1].text.slice(0,180)}...
                </Text>
              </div>
              <LinkText onClick={()=>navigate("/Eservices")}>
                Get started <i class="fa-solid fa-arrow-right-long"></i>
              </LinkText>
            </Card>

            <Card>
              <div>
                <Icon src={ServicesIcon7} />
                <HeadText>VAT Registration & Consultancy</HeadText>
                <Text>
                {Data[4].text.slice(0,180)}...
                </Text>
              </div>
              <LinkText onClick={()=>navigate("/VATRegistration")}>
                Get started <i class="fa-solid fa-arrow-right-long"></i>
              </LinkText>
            </Card>

            <Card>
              <div>
                <Icon src={ServicesIcon8} />
                <HeadText>E-trader License</HeadText>
                <Text>
                {Data[2].text.slice(0,180)}...
                </Text>
              </div>
              <LinkText onClick={()=>navigate("/EtraderLicense")}>
                Get started <i class="fa-solid fa-arrow-right-long"></i>
              </LinkText>
            </Card>


          


          </Carousel>
        </CardSection>
        <Container>
          <UserInfoSection />
        </Container>
      </InfoSection>
    </Root>
  );
}
