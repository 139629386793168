import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Step1Logo from "../../Images/step1.png";
import Step2Logo from "../../Images/step2.png";
import Step3Logo from "../../Images/step3.png";
import Step4Logo from "../../Images/step4.png";
import TraingleImage from "../../Images/triangle.png";

const Root = styled.div`
  width: 100%;
  padding: 100px 0;
`;
const Container = styled.div`
  width: 1160px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1200px) {
    padding: 0 20px;
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`;
const Wrapper = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
`;
const TextWrapper = styled.div`
  padding-right: 80px;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    padding-right: 0;
  }
`;
const Heading = styled.div`
  font-size: 46px;
  font-weight: 900;
  margin-bottom: 30px;
`;
const Text = styled.div`
  font-weight: 300;
  color: #807d7e;
  letter-spacing: 0.8px;
  line-height: 150%;
  font-size: 14px;
`;
const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;
const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 20px);
  background: #f5f5ef;
  padding: 20px 50px;
  border-radius: 10px;
  align-items: center;
`;
const IconWrapper = styled.div`
  ${(p) =>
    p.color
      ? `
  background-color:${p.color};
`
      : `
background-color: #ed1b23;
`}
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const HeadText = styled.div`
  margin: 10px 0;
  font-size:18px;
  font-weight: 400;
`;
const Icon = styled.img`
  width: 20px;
`;
const ButtonWrapper = styled.div`
  margin: 50px 0 0 0;
`;
const Button = styled.button`
  background-color: #23744e;
  border: 1px solid #ffb300;
  padding: 15px 30px;
  color: #ffb300;
  position: relative;
  font-weight: 600;
  cursor: pointer;
  font-size: 16px;
`;
const Image = styled.img`
  position: absolute;
  height: 48.7px;
  top: 0;
  right: 0;
`;
const Card = ({ headText, text1, icon, color,text2 }) => {
  // const navigate = useNavigate();
  return (
    <CardItem>
      <IconWrapper color={color}>
        <Icon src={icon} />
      </IconWrapper>
      <HeadText>{headText}</HeadText>
      <Text style={{ textAlign: "center" }}>{text1}</Text>
      <Text style={{ textAlign: "center" }}>{text2}</Text>
    </CardItem>
  );
};
function StartBussinessStep(props) {
  const navigate = useNavigate();
  return (
    <Root>
      <Container>
        <Wrapper>
          <TextWrapper>
            <Heading>
            Dubai Business  
              <br /> Registration
              <br /> and Licensing
               {/* <span style={{ color: "#0b9e5f" }}>Dubai </span> */}
            </Heading>
            <Text>
            We create a pathway for experienced entrepreneurs ready to establish, operate and actively manage a business in Dubai. 
            </Text>
          </TextWrapper>
          <ButtonWrapper>
            <Button onClick={()=> navigate("/ContactUs")}>
            Book an appointment<Image src={TraingleImage} />
            </Button>
          </ButtonWrapper>
        </Wrapper>
        <Wrapper>
          <CardWrapper>
            <Card
              headText="Step1"
              text1="Verify all Necessary"
              text2="Documents"
              icon={Step1Logo}
            />
            <Card
              color="#ffb300"
              headText="Step2"
              text1="Apply for Business"
              text2="License"
              icon={Step2Logo}
            />
            <Card
              color="#ffb300"
              headText="Step3"
              text1="Start Dubai Business"
              text2="Visa Process"
              icon={Step4Logo}
            />
            <Card
              headText="Step4"
              text1="Obtain Bank"
              text2="Account in Dubai"
              icon={Step3Logo}
            />
          </CardWrapper>
        </Wrapper>
      </Container>
    </Root>
  );
}

export default StartBussinessStep;
