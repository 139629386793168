import "./App.css";
import styled from "styled-components";
import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Services from "./Pages/Services/Services";
import OurConcept from "./Pages/OurConcept/OurConcept";
import CenterFeatures from "./Pages/CenterFeatures/CenterFeatures.js";
import Blog from "./Pages/Blog/Blog.js";
import ContactUs from "./Pages/ContactUs/ContactUs.js";
import FAQ from "./Pages/FAQ/Faq";
import CompanyRegistration from "./Pages/Services/InnerServices/CompanyRegistration/CompanyRegistration";
import EjariRegistration from "./Pages/Services/InnerServices/EjariRegistration/EjariRegistration.js";
import CommercialLicense from "./Pages/Services/InnerServices/CommercialLicense/CommercialLicense.js";
import Eservices from "./Pages/Services/InnerServices/Eservices/Eservices.js";
import EtraderLicense from "./Pages/Services/InnerServices/EtraderLicense/EtraderLicense.js";
import LLCCompanyFormation from "./Pages/Services/InnerServices/LLCCompanyFormation/LLCCompanyFormation.js";
import PROVisa from "./Pages/Services/InnerServices/PROVisa/PROVisa.js";
import VATRegistration from "./Pages/Services/InnerServices/VATRegistration/VATRegistration.js";
import Termsofuse from "./Pages/Policy/Termsofuse";
import { useContext } from "react";
import { StateManagementContext } from "./ContextApi/ContextApi";
import ScrollToTop from "./Components/ScrollToTop";
const Root = styled.div``;
function App() {
  const { setShowDropDown, setShowNavigation } = useContext(
    StateManagementContext
  );
  return (
    <Root
      onClick={() => {
        setShowDropDown(false);
        setShowNavigation(false);
      }}
    >
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/OurConcept" element={<OurConcept />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/CompanyRegistration" element={<CompanyRegistration />} />
        <Route path="/EjariRegistration" element={<EjariRegistration />} />
        <Route path="/Eservices" element={<Eservices />} />
        <Route path="/EtraderLicense" element={<EtraderLicense />} />
        <Route path="/LLCCompanyFormation" element={<LLCCompanyFormation />} />
        <Route path="/PROVisa" element={<PROVisa />} />
        <Route path="/VATRegistration" element={<VATRegistration />} />
        <Route path="/CommercialLicense" element={<CommercialLicense />} />
        <Route path="/CenterFeatures" element={<CenterFeatures />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/FAQ" element={<FAQ />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/Termsofuse" element={<Termsofuse />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
    </Root>
  );
}

export default App;
