import React from "react";
import styled from "styled-components";
import Navigation from "../../Components/Navigation";
import BgImage from "../../Images/Services-banner-bg.jpg";

const Root = styled.div`
  background-image: url(${BgImage});
  padding-bottom: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const Heading = styled.div`
  color: #fff;
  margin-top: 60px;
  font-size: 36px;
  font-weight: 400;
  @media(max-width: 490px){
font-size: 30px;
margin-top: 40px;
  }
`;
const Button = styled.button`
  color: #ffb300;
  border: 1px solid #ffb300;
  background: none;
  margin-top: 20px;
  padding: 15px 30px;
`;
const Container = styled.div`
  width: 1160px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 20px;
  }
`;
const SearchBox = styled.input`
  margin-top: 20px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  outline: none;
  color: #fff;
  padding: 20px 20px 20px 50px;
  border-radius: 5px;
`;
const SearchBoxWrapper = styled.div`
width:100%;
position: relative;
`;
const IconWrapper = styled.div`
position: absolute;
top:37px;
color:#fff;
left:20px;
`;
export default function HeroSection() {
  return (
    <Root>
      <Navigation />
      <Heading>How can we help you?</Heading>
      <Container>
        <SearchBoxWrapper>
          <SearchBox placeholder="Search for answers" type="search" />
          <IconWrapper>
          <i class="fa-solid fa-magnifying-glass"></i>
          </IconWrapper>
        </SearchBoxWrapper>
      </Container>
    </Root>
  );
}
