import React from "react";
import styled from "styled-components";
import TriangleImage from "../../Images/triangle.png";
import { useNavigate } from "react-router-dom";


const Root = styled.div``;
const QuestionSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.2px solid #23744e;
  padding-bottom: 30px;
  @media(max-width:767px){
    flex-direction: column;
    justify-content: left;
  }
`;
const Wrapper = styled.div`
 @media(max-width:767px){
  width:100%;
  margin-bottom:20px;
  text-align: left;
 }
`;
const Text = styled.div`
  color: #fff;
  font-weight: 300;
`;
const Button = styled.button`
  background-color: #23744e;
  border: 1px solid #ffb300;
  padding: 15px 30px;
  color: #ffb300;
  font-size: 16px;
  font-weight: 700;
  position: relative;
  cursor: pointer;
`;
const HeadText = styled.div`
  color: #ffb300;
  font-size: 95px;
  font-weight: 300;
  /* margin-top: 20px; */
@media(max-width:767px){
  font-size:50px;
}
`;
const LargeText = styled.div`
  display: flex;
  color: #23744e;
  font-size: 200px;
  display: flex;
  line-height: 0.7;
  align-items: baseline;
  @media(max-width:767px){
    font-size:60px;
  }
`;
const LinkTetx = styled.div`
  color: #ffb300;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
`;
const Image = styled.img`
  position: absolute;
  height: 48px;
  top: 0;
  right: -1px;
`;
export default function UserInfoSection() {
  const navigate = useNavigate();

  return (
    <Root>
      <QuestionSection>
        <Wrapper>
          <Text style={{ fontWeight: "bold", marginBottom: "10px" }}>
          Your success is our success.
          </Text>
          <Text>
          Dubai startup is committed to providing one-on-one guidance to Dubai business rules and connecting <br/> them to supports and resources tailored to specific needs.
          </Text>
        </Wrapper>
        <Button 
        onClick={()=>navigate("/services")}
        >
          View all service <Image src={TriangleImage} />
        </Button>
      </QuestionSection>
      <HeadText>Latest</HeadText>
      <LargeText>
        Insights
        <LinkTetx onClick={()=> navigate("/ContactUs")}>
          Get started <i class="fa-solid fa-arrow-right-long"></i>
        </LinkTetx>
      </LargeText>
    </Root>
  );
}
