import React from "react";
import styled from "styled-components";
import LogoImage1 from "../../Images/logo1.jpg";
import LogoImage2 from "../../Images/logo2.jpg";

const Root = styled.div`
  padding: 40px 0;
  @media(max-width: 550px){
padding: 0px 0px 40px 0;
  }
`;
const Container = styled.div`
  width: 1160px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  @media (max-width: 1200px) {
    padding: 0 20px;
    width: 100%;
  }
  @media(max-width:550px){
display: flex;
flex-direction: column;
justify-content: center;
text-align: center;
align-items: center;
  }
`;
const Image = styled.img``;
export default function CompanySection() {
  return (
    <Root>
      <Container>
        <Image src={LogoImage2} />
        <Image src={LogoImage1} />
      </Container>
    </Root>
  );
}
