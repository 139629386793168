import React, { useState } from "react";
import styled from "styled-components";
import FooterPageLink from "./FooterPageLink";

const Root = styled.div`
background: linear-gradient(180deg, rgb(0, 0, 0) 20%, rgb(1 58 32) 100%);
`;
const Text = styled.div`
   font-size:16px;
   color: #006539;
   text-align:center;
   `;
const Container = styled.div`
width:1200px;
margin:0 auto;
padding:40px 0 0 0;
@media(max-width:1240px){
   padding:20px 20px 0 20px;
   width:100%;
}
`;
const Heading = styled.div`
  font-size:30px;
  color:#fff;
  font-weight:bold;
  text-align:center;
  @media(max-width:532px){
      font-size:24px;
  }
`;

const InputWrapper = styled.div`
 width:446px;
 max-width:446px;
 margin:0 auto;
 position:relative;
 margin-top:10px;
 margin-bottom:40px;

 @media(max-width:500px){
     width:100%;
 }
`;
const Input = styled.input`
 width:100%;
 border-radius:50px;
 padding:15px 20px;
 border:none;
 outline:none;

`;

const Button = styled.button`
 position:absolute;
 top:2px;
 right:2px;
 bottom:2px;
 font-weight:500;
 background-color: #ffb300;
 border:none;
 padding:13px 30px;
 border-radius:50px;
`;
const FooterText = styled.div`
border-top: 1px solid #b6e1cd27;
margin-top:30px;
font-size: 13px;
color: #b6e1cd;
text-align: center;
padding: 20px 0px;
`;
const Span = styled.span`
color: #fff;
`;
const Footer = () => {
    const [email, setEmail] = useState('');

    const clickHandler = (e) => {
        e.preventDefault();
        setEmail('');
    }
    return (
        <Root>
            <Container>
                {/* <Text>To receive our best offers</Text>
                <Heading>JOIN THE NEWSLETTER</Heading> */}
                {/* <InputWrapper>
                    <Input 
                      placeholder="Your Email Address" 
                      name="email"
                      value={email} 
                      onChange={(e) => setEmail(e.target.value)} />
                   <Button type="button" onClick={clickHandler}>Subscribe</Button>
                </InputWrapper> */}
                <FooterPageLink/>
                <FooterText>
                Copyrights © 2022. All rights reserved - Powered By <Span> OAR PROJECT MANAGEMENT SERVICES L.L.C</Span>
                
                </FooterText>
            </Container>
        </Root>
    );
};

export default Footer;
